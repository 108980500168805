import { ErrorProvider } from "../shared/ErrorContext";
import { PortalClientContainer } from "../account/PortalClient";

const PortalSite = () => {
    return (
        <div className="backPublicSmooth">
            <ErrorProvider>
                <PortalClientContainer />
                <footer />
            </ErrorProvider>
        </div>
    );
};

export default PortalSite;
