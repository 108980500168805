import { FileUploadResponse } from "../pages/file/entities/FileUploadResponse";
import RequestService from "./RequestService";
import CompanyService from "./CompanyService";

export type MultiuploadResultType = { files: { id: string, filename: string }[] };

class FileService {
    public static async download(fileId: string, fileName: string = "export.xlsx") {
        return RequestService.downloadFile("/file/download?id=" + fileId, fileName);
    }

    public static async downloadPost(fileId: string, fileName: string = "export.xlsx") {
        return RequestService.downloadFilePost("/file/download", fileId, fileName);
    }

    public static async downloadTask(fileId: string, taskId: string, fileName: string) {
        return RequestService.downloadFile(`/file/downloadtaskfile?id=${fileId}&taskid=${taskId}`, fileName);
    }

    public static async downloadFileMessage(fileId: string, messageid: string | number, fileName: string) {
        return RequestService.downloadFile(`/file/downloadmessagefile?attachmentid=${fileId}&messageid=${messageid}`, fileName);
    }

    public static async downloadIO(fileId: string, ioid: string, fileName: string) {
        return RequestService.downloadFile(`/file/downloadiofiles?ioFileID=${fileId}&ioid=${ioid}`, fileName);
    }

    public static async downloadAction(fileId: string, actionId: string, fileName: string) {
        return RequestService.downloadFile(`/file/DownloadActionAttachment?actionAttachmentId=${fileId}&actionid=${actionId}`, fileName);
    }

    public static async downloadActionLetterTemplate(actionId: string) {
        return RequestService.downloadFile(`/file/DownloadActionTemplateFile?actionid=${actionId}`, 'template.doc');
    }

    public static async downloadReceipt(receiptid: string) {
        return RequestService.downloadFile(`/file/downloadreceipt?id=${receiptid}`);
    }

    public static async downloadImportTemplate(fileId: string, fileName: string = "template.xlsx") {
        return RequestService.downloadFile("/file/downloadimporttemplate?id=" + fileId, fileName);
    }

    public static async downloadEmailTemplateFile(mailTemplateId: string, fileId: string, fileName: string) {
        return RequestService.downloadFile("/file/downloadmailtemplate?mailTemplateId=" + mailTemplateId + "&attachmentId=" + fileId, fileName);
    }

    public static getThumbSrcUrl(attachmentid: number, messageid: number) {
        return (RequestService.getBaseUrl() + "/file/getmessagefilethumb?attachmentid=" + attachmentid + "&messageid=" + messageid + '&token=' + CompanyService.getCompanyAuth()?.companyToken);
    }

    public static getSrcUrl(attachmentid: number, messageid: number) {
        return (RequestService.getBaseUrl() + "/file/getmessagefile?attachmentid=" + attachmentid + "&messageid=" + messageid + '&token=' + CompanyService.getCompanyAuth()?.companyToken);
    }

    public static downloadTempFile(file: File): void {
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }

    public static async downloadReceiptTemplate() {
        return RequestService.downloadFile(`/file/downloadreceipttemplate`);
    }

    public static async downloadLastBackup() {
        return RequestService.downloadFile(`/file/downloadbackup`);
    }

    public static async deleteReceiptTemplate() {
        return RequestService.post('/receipt/deletetemplate', {})
    }

    public static uploadReceiptTemplate(fileid: string): Promise<FileUploadResponse | Error> {
        return RequestService.post("/receipt/settemplate", { fileid });
    }

    public static upload(file: File): Promise<FileUploadResponse | Error> {
        const formData = new FormData();
        formData.append("File", file, file.name);
        return RequestService.post("/file/upload", formData);
    }

    public static multiupload(files: File[]): Promise<MultiuploadResultType | Error> {
        const formData = new FormData();
        files.forEach(x => formData.append("File", x, x.name));
        return RequestService.post("/file/multiupload", formData);
    }

    public static downloadReport(id: string) {
        return RequestService.downloadFile(`/file/downloadreport?id=${id}`, `report-file_${id}`)
    }
}

export default FileService;