import React, { useCallback, useEffect, useMemo, useState } from "react";
import Collapsable from "../../../shared/bootstrap/Collapsable";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../services/TranslationService";
import { RequiredManager } from "../../../shared/RequieredManager";
import FieldList from "./Components/FieldList";
import { CompanyAuth } from "../../../../entities/login/CompanyAuth";
import { ToastService } from "../../../shared/bootstrap/Toast";
import ImportTemplateService, { ImportTemplateId } from "../../../../services/ImportTemplateService";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import AdditionalDefinitionsEdit from "../FieldConfiguration/AdditionalDefinitionsEdit";
import CompanyService, { Entities } from "../../../../services/CompanyService";
import FieldConfigurationService from "../../../../services/FieldConfigurationService";
import ModalService, { ModalParams } from "../../../shared/bootstrap/Modal";
import Loading from "../../../shared/components/Loading";
import FloatingPanelService from "../../../shared/FloatingPanel";

export type ImportTemplateComponentProps = {
    data: ImportTemplateListResponse.Item | undefined,
    updateModel: UpdateModelFunc,
    model: Partial<ImportTemplateListResponse.Item>,
    setModel: React.Dispatch<React.SetStateAction<Partial<ImportTemplateListResponse.Item>>>,
    requiredManager: RequiredManager,
    requiredFieldIds: string[],
    importsList: ImportTemplateListResponse | undefined,
}

const ImportTemplateEditBase = ({ data, reload, requiredFieldIds, Header, AdvancedOptions = undefined, runNow = undefined, importsList = undefined, entityId: originalEntityId = undefined }: {
    data?: ImportTemplateListResponse.Item, reload: () => void, requiredFieldIds: string[], Header: React.FC, AdvancedOptions?: React.FC<ImportTemplateComponentProps>,
    runNow?: () => void, importsList?: ImportTemplateListResponse, entityId?: number,
}) => {
    const { translate } = TranslationService;
    const entityId = useMemo(() => data?.entity ?? originalEntityId, [data?.entity, originalEntityId]);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [model, setModel] = useState<Partial<ImportTemplateListResponse.Item>>(() => getDefaultModel(data, entityId));
    const [submiting, setSubmiting] = useState(false);
    const [changeFields, setChangeFields] = useState(true);

    const requiredManager = useMemo(() => new RequiredManager(), []);

    const fields: TemplateField[] = useMemo(() => {
        if (entityId !== undefined || (changeFields && entityId)) {
            const resultFields = ImportTemplateService.getTemplateFields(entityId);
            setChangeFields(false);
            ModalService.hideModal();
            return resultFields;
        } else {
            return [];
        }
    }, [entityId, changeFields]);

    const sendNowModal = new ModalParams();

    const requestData = async () => {
        sendNowModal.children = <Loading />;
        const result = await FieldConfigurationService.getAdditionalDefinitionList();

        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        setChangeFields(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const requestDataCallback = useCallback(requestData, []);

    sendNowModal.children = <AdditionalDefinitionsEdit reload={requestDataCallback} notHideFloatingPanel entityId={Entities.Invoice} />;
    sendNowModal.closeClickingOutside = true;

    const onSubmit = async () => {
        if (submiting) {
            return;
        }
        if (!requiredManager.validate()) {
            ToastService.showToast(translate.MissingRequiredFields);
            return;
        }
        const columns = model.Columns?.map(x => x.ix).filter(x => x);
        const columnsCount = columns?.length;
        const set = new Set(columns);
        if ([...set].length !== (columnsCount ?? 0)) {
            ToastService.showToast(TranslationService.translate.ImportTemplateConfigurationRepeatedColumns, undefined, "warning");
            return;
        }

        setSubmiting(true);
        const result = await ImportTemplateService.set(model as ImportTemplateListResponse.Item);
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        ToastService.showToast(translate.ImportTemplateSaved, undefined, "success");
        setSubmiting(false);
        FloatingPanelService.hidePanel();
        reload();
    };

    const updateModel: UpdateModelFunc = <T extends keyof ImportTemplateListResponse.Item,>(key: T) => (value: ImportTemplateListResponse.Item[T]) => {
        setModel(x => ({ ...x, [key]: value }));
    };

    const props: ImportTemplateComponentProps = {
        data,
        updateModel,
        model,
        setModel,
        requiredManager,
        requiredFieldIds,
        importsList,
    };

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const showAdditionalDefinitionsEditModal = () => {
        ModalService.showModal(sendNowModal);
    };

    return (
        <>
            <div className="floatingBody p-4">
                <Header />
                <FieldList
                    data={data}
                    updateModel={updateModel}
                    model={model}
                    setModel={setModel}
                    fields={fields}
                    requiredFieldIds={fields.filter(x => x.required).map(x => x.fieldId)}
                    requiredManager={requiredManager}
                />
                {![ImportTemplateId.Transaction, ImportTemplateId.Dictionary].includes(entityId ?? -1) && <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={showAdditionalDefinitionsEditModal}><i className="fal fa-plus" /> {translate.CreateNewField}</button>
                </div>}
                {AdvancedOptions && <>
                    <button className="btn btn-link mb-2 d-block ps-0" onClick={() => setShowAdvanced(x => !x)}>{showAdvanced ? translate.HideAdvancedOptions : translate.ShowAdvancedOptions}</button>
                    <Collapsable show={showAdvanced}>
                        <AdvancedOptions {...props} />
                    </Collapsable>
                </>}
            </div>
            <FloatingPanelFooter>
                {runNow && <button className='btn btn-secondary' onClick={runNow}>
                    {translate.RunNow}
                </button>}
                <button className='btn btn-primary' onClick={onSubmit}>
                    {translate.Save}
                    {submiting && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};

export function createFieldData(key: string, label: string | (() => JSX.Element), required: boolean = false): TemplateField {
    return {
        fieldId: key,
        key: key,
        label,
        required,
    };
}

export function additionalDefsToFieldData(additionalDefs: CompanyAuth.AdditionalDefinition[]) {
    const personKey = CompanyService.getPersonKey();
    const allowCustomFieldImport = CompanyService.getSetting("allowcustomfieldimport");
    return additionalDefs.map(x => (
        {
            fieldId: x.AdditionalDefinitionID.toString(),
            key: "additional-" + x.AdditionalDefinitionID.toString(),
            label: x.Name,
            required: personKey === x.AdditionalDefinitionID && allowCustomFieldImport,
        }));
}

function getDefaultModel(data: ImportTemplateListResponse.Item | undefined, entityId: number | undefined) {
    if (data) {
        data.templateFileID = data.BlobName;
    }
    return data ?? { Sheet: "num-1", Entity: entityId!, entity: entityId! };
}

export const sheetColumns: readonly { text: string; value: number; }[] = Array.from({ length: 10 }, (_, i) => i + 9).flatMap(x => Array.from({ length: 26 }, (_, i) => i + 10).map(y => x.toString(36).replace("9", "").toUpperCase() + y.toString(36).toUpperCase())).map((x, i) => ({ text: x, value: i + 1 }));

export type TemplateField = { fieldId: string; key: string; label: string | (() => JSX.Element); required?: boolean };
export type UpdateModelFunc = <T extends keyof ImportTemplateListResponse.Item>(key: T) => (value: ImportTemplateListResponse.Item[T]) => void;

export default ImportTemplateEditBase;