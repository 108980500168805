import { Link } from "react-router-dom";
import { OptionTab } from "../../shared/ScrollNavChips";

export const PortalHeader = (props: {
    personName: string, logo?: string,
    tabs: OptionTab[],
    activeTab: number,
    setActiveTab: (val: number) => void,
}) => {
    return (
        <header>
            <nav className="navbar navbar-expand-sm bg-white mb-3">
                <div className="row align-items-center">
                    <div className="col-12 col-md-auto aling-self-center text-center mt-4 mt-md-0">
                        {(props?.logo && props.logo?.length > 0) ?
                            <Link to={"/"} className="navbar-brand"><img src={props.logo} alt="Cliente" className="heder-public" /></Link> :
                            <h5 className="card-body m-3">{props.personName}</h5>}
                    </div>

                    <div className="col-12 col-md-auto navbar-collapse">
                        <ul className="navbar-nav me-auto mb-0 py-0">
                            {props.tabs.map((x, i) => <NavItem key={i} {...{ ...x, iconActive: x.iconActive, iconInactive: x.iconInactive, activeTab: props.activeTab, setActiveTab: props.setActiveTab }} />)}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

const NavItem = (props: OptionTab) => {
    const isActive = props.index === props.activeTab;
    const onSetActiveTab = () => props.setActiveTab(props.index);

    return (
        <li className={"nav-item pointer nav-itemO" + (isActive ? " active" : "")}>
            <div onClick={onSetActiveTab} className="nav-link nav-itemO" aria-current="page" >
                <p>{props.title}</p>
                <hr />
            </div>
        </li>
    )
};