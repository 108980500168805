export const HomeIconActive = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0001 6L10.5001 2.055C10.0876 1.68604 9.55352 1.48206 9.00007 1.48206C8.44662 1.48206 7.91259 1.68604 7.50007 2.055L3.00007 6C2.76185 6.21306 2.57175 6.47442 2.44243 6.76669C2.3131 7.05895 2.24753 7.37542 2.25007 7.695V14.25C2.25007 14.8467 2.48712 15.419 2.90908 15.841C3.33104 16.263 3.90333 16.5 4.50007 16.5H13.5001C14.0968 16.5 14.6691 16.263 15.0911 15.841C15.513 15.419 15.7501 14.8467 15.7501 14.25V7.6875C15.7515 7.36918 15.6855 7.05416 15.5562 6.76327C15.4269 6.47237 15.2373 6.21222 15.0001 6ZM10.5001 15H7.50007V11.25C7.50007 11.0511 7.57909 10.8603 7.71974 10.7197C7.86039 10.579 8.05116 10.5 8.25007 10.5H9.75007C9.94898 10.5 10.1397 10.579 10.2804 10.7197C10.4211 10.8603 10.5001 11.0511 10.5001 11.25V15ZM14.2501 14.25C14.2501 14.4489 14.1711 14.6397 14.0304 14.7803C13.8897 14.921 13.699 15 13.5001 15H12.0001V11.25C12.0001 10.6533 11.763 10.081 11.3411 9.65901C10.9191 9.23706 10.3468 9 9.75007 9H8.25007C7.65333 9 7.08104 9.23706 6.65908 9.65901C6.23712 10.081 6.00007 10.6533 6.00007 11.25V15H4.50007C4.30116 15 4.11039 14.921 3.96974 14.7803C3.82909 14.6397 3.75007 14.4489 3.75007 14.25V7.6875C3.75021 7.58102 3.77302 7.47578 3.81698 7.37879C3.86095 7.2818 3.92507 7.19529 4.00507 7.125L8.50507 3.1875C8.64194 3.06727 8.81789 3.00095 9.00007 3.00095C9.18225 3.00095 9.3582 3.06727 9.49507 3.1875L13.9951 7.125C14.0751 7.19529 14.1392 7.2818 14.1832 7.37879C14.2271 7.47578 14.2499 7.58102 14.2501 7.6875V14.25Z" fill="#0076B6" />
        </svg>
    )
}

export const HomeIconInactive = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0001 6L10.5001 2.055C10.0876 1.68604 9.55352 1.48206 9.00007 1.48206C8.44662 1.48206 7.91259 1.68604 7.50007 2.055L3.00007 6C2.76185 6.21306 2.57175 6.47442 2.44243 6.76669C2.3131 7.05895 2.24753 7.37542 2.25007 7.695V14.25C2.25007 14.8467 2.48712 15.419 2.90908 15.841C3.33104 16.263 3.90333 16.5 4.50007 16.5H13.5001C14.0968 16.5 14.6691 16.263 15.0911 15.841C15.513 15.419 15.7501 14.8467 15.7501 14.25V7.6875C15.7515 7.36918 15.6855 7.05416 15.5562 6.76327C15.4269 6.47237 15.2373 6.21222 15.0001 6ZM10.5001 15H7.50007V11.25C7.50007 11.0511 7.57909 10.8603 7.71974 10.7197C7.86039 10.579 8.05116 10.5 8.25007 10.5H9.75007C9.94898 10.5 10.1397 10.579 10.2804 10.7197C10.4211 10.8603 10.5001 11.0511 10.5001 11.25V15ZM14.2501 14.25C14.2501 14.4489 14.1711 14.6397 14.0304 14.7803C13.8897 14.921 13.699 15 13.5001 15H12.0001V11.25C12.0001 10.6533 11.763 10.081 11.3411 9.65901C10.9191 9.23706 10.3468 9 9.75007 9H8.25007C7.65333 9 7.08104 9.23706 6.65908 9.65901C6.23712 10.081 6.00007 10.6533 6.00007 11.25V15H4.50007C4.30116 15 4.11039 14.921 3.96974 14.7803C3.82909 14.6397 3.75007 14.4489 3.75007 14.25V7.6875C3.75021 7.58102 3.77302 7.47578 3.81698 7.37879C3.86095 7.2818 3.92507 7.19529 4.00507 7.125L8.50507 3.1875C8.64194 3.06727 8.81789 3.00095 9.00007 3.00095C9.18225 3.00095 9.3582 3.06727 9.49507 3.1875L13.9951 7.125C14.0751 7.19529 14.1392 7.2818 14.1832 7.37879C14.2271 7.47578 14.2499 7.58102 14.2501 7.6875V14.25Z" fill="#595959" />
        </svg>
    )
}
