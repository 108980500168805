import { useContext, useState } from "react";
import { CollapsableConfiguration } from "./OtherConfigurationItems/CollapsableConfiguration";
import { SearchBar } from "./SearchBar";
import { TranslationService } from "../../../services/TranslationService";
import ConfigurationContext from "../ConfigurationContext";
import Loading from "../../shared/components/Loading";
import CompanyService from "../../../services/CompanyService";
import { parseIntOrDefault } from "../../../utils/ParseUtils";
import ActivityService from "../../../services/ActivityService";
import ModalService from "../../shared/bootstrap/Modal";

const OtherConfiguration = () => {
    const { otherConfigurationList } = useContext(ConfigurationContext);
    const [valueSearch, setValueSearch] = useState("");

    return (
        <div className="card px-5">
            <SearchBar onSearch={setValueSearch} />

            <div className="d-flex flex-row flex-nowrap justify-contnt-between align-items-center my-4">
                <h2>{TranslationService.translate.OtherSettings}</h2>
            </div>

            <table className="table shadow-hover collapsableFolder">
                <tbody>
                    {otherConfigurationList ? otherConfigurationList?.map(({ titleGroup, childrenGroup }, index) =>
                        <CollapsableConfiguration
                            key={index + titleGroup}
                            titleGroup={titleGroup}
                            children={childrenGroup}
                            valueSearch={valueSearch} />
                    ) : <Loading />}
                </tbody>
            </table>
            {parseIntOrDefault(CompanyService.getCompanyToken()?.adminid, 0) > 0 &&
                <button className="btn btn-outline-primary w-250px"
                    onClick={() => ModalService.showDefaultModal({
                        acceptButtonLabel: "Marcar",
                        message: "Está seguro de marcar todos los emails como leídos?",
                        onAcceptClick: ActivityService.markAllAsRead,
                    })}>Marcar emails como leídos
                </button>}
        </div>
    );
};

export default OtherConfiguration;