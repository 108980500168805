export const PaymentsIconActive = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.94185 12.634C1.81291 12.634 1.68926 12.5828 1.59808 12.4916C1.50691 12.4004 1.45569 12.2768 1.45569 12.1478V2.42462C1.45569 2.29569 1.50691 2.17203 1.59808 2.08086C1.68926 1.98968 1.81291 1.93846 1.94185 1.93846H10.9358C11.1292 1.93846 11.3147 1.86163 11.4515 1.72487C11.5882 1.58811 11.6651 1.40263 11.6651 1.20922C11.6651 1.01581 11.5882 0.83033 11.4515 0.69357C11.3147 0.556811 11.1292 0.47998 10.9358 0.47998H1.94185C1.4261 0.47998 0.931472 0.684862 0.56678 1.04955C0.202089 1.41424 -0.00279236 1.90887 -0.00279236 2.42462V12.1478C-0.00279236 12.6636 0.202089 13.1582 0.56678 13.5229C0.931472 13.8876 1.4261 14.0925 1.94185 14.0925H11.6651C12.1808 14.0925 12.6754 13.8876 13.0401 13.5229C13.4048 13.1582 13.6097 12.6636 13.6097 12.1478V8.98779C13.6097 8.79439 13.5329 8.6089 13.3961 8.47214C13.2594 8.33538 13.0739 8.25855 12.8805 8.25855C12.6871 8.25855 12.5016 8.33538 12.3648 8.47214C12.2281 8.6089 12.1512 8.79439 12.1512 8.98779V12.1478C12.1512 12.2768 12.1 12.4004 12.0088 12.4916C11.9177 12.5828 11.794 12.634 11.6651 12.634H1.94185ZM14.3681 4.05812C14.4969 3.91988 14.5671 3.73704 14.5637 3.54812C14.5604 3.3592 14.4839 3.17894 14.3502 3.04533C14.2166 2.91172 14.0364 2.83519 13.8475 2.83186C13.6585 2.82852 13.4757 2.89865 13.3375 3.02746L7.93329 8.43065L6.11214 6.54824C6.04569 6.47881 5.96618 6.4232 5.87817 6.3846C5.79016 6.34601 5.69539 6.32519 5.59931 6.32334C5.50323 6.32149 5.40773 6.33865 5.3183 6.37384C5.22887 6.40902 5.14728 6.46153 5.07822 6.52836C5.00915 6.59518 4.95397 6.67499 4.91586 6.76321C4.87774 6.85143 4.85744 6.94631 4.85612 7.0424C4.85479 7.13849 4.87248 7.2339 4.90815 7.32313C4.94382 7.41237 4.99678 7.49367 5.06397 7.56237L7.40046 9.97762C7.46769 10.0473 7.54813 10.103 7.6371 10.1413C7.72606 10.1796 7.82176 10.1998 7.91862 10.2007C8.01547 10.2016 8.11153 10.1832 8.20119 10.1465C8.29086 10.1099 8.37233 10.0558 8.44085 9.98734L14.3681 4.05812Z" fill="#0076B6" />
        </svg>
    )
}

export const PaymentsIconInactive = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.94185 12.634C1.81291 12.634 1.68926 12.5828 1.59808 12.4916C1.50691 12.4004 1.45569 12.2768 1.45569 12.1478V2.42462C1.45569 2.29569 1.50691 2.17203 1.59808 2.08086C1.68926 1.98968 1.81291 1.93846 1.94185 1.93846H10.9358C11.1292 1.93846 11.3147 1.86163 11.4515 1.72487C11.5882 1.58811 11.6651 1.40263 11.6651 1.20922C11.6651 1.01581 11.5882 0.83033 11.4515 0.69357C11.3147 0.556811 11.1292 0.47998 10.9358 0.47998H1.94185C1.4261 0.47998 0.931472 0.684862 0.56678 1.04955C0.202089 1.41424 -0.00279236 1.90887 -0.00279236 2.42462V12.1478C-0.00279236 12.6636 0.202089 13.1582 0.56678 13.5229C0.931472 13.8876 1.4261 14.0925 1.94185 14.0925H11.6651C12.1808 14.0925 12.6754 13.8876 13.0401 13.5229C13.4048 13.1582 13.6097 12.6636 13.6097 12.1478V8.98779C13.6097 8.79439 13.5329 8.6089 13.3961 8.47214C13.2594 8.33538 13.0739 8.25855 12.8805 8.25855C12.6871 8.25855 12.5016 8.33538 12.3648 8.47214C12.2281 8.6089 12.1512 8.79439 12.1512 8.98779V12.1478C12.1512 12.2768 12.1 12.4004 12.0088 12.4916C11.9177 12.5828 11.794 12.634 11.6651 12.634H1.94185ZM14.3681 4.05812C14.4969 3.91988 14.5671 3.73704 14.5637 3.54812C14.5604 3.3592 14.4839 3.17894 14.3502 3.04533C14.2166 2.91172 14.0364 2.83519 13.8475 2.83186C13.6585 2.82852 13.4757 2.89865 13.3375 3.02746L7.93329 8.43065L6.11214 6.54824C6.04569 6.47881 5.96618 6.4232 5.87817 6.3846C5.79016 6.34601 5.69539 6.32519 5.59931 6.32334C5.50323 6.32149 5.40773 6.33865 5.3183 6.37384C5.22887 6.40902 5.14728 6.46153 5.07822 6.52836C5.00915 6.59518 4.95397 6.67499 4.91586 6.76321C4.87774 6.85143 4.85744 6.94631 4.85612 7.0424C4.85479 7.13849 4.87248 7.2339 4.90815 7.32313C4.94382 7.41237 4.99678 7.49367 5.06397 7.56237L7.40046 9.97762C7.46769 10.0473 7.54813 10.103 7.6371 10.1413C7.72606 10.1796 7.82176 10.1998 7.91862 10.2007C8.01547 10.2016 8.11153 10.1832 8.20119 10.1465C8.29086 10.1099 8.37233 10.0558 8.44085 9.98734L14.3681 4.05812Z" fill="#595959" />
        </svg>
    )
}
