import DatePicker, { registerLocale } from "react-datepicker";
import { TranslationService } from "../../../services/TranslationService";
import "react-datepicker/dist/react-datepicker.css";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import Select, { components, } from "react-select";
import Creatable from "react-select/creatable";
import { getStatusList, TaskStatus } from "../../task/entities/TaskStatus";
import Dropdown from "./Dropdown";
import AsyncSelect from "react-select/async";
import AsyncCreateSelect from "react-select/async-creatable";
import FileService from "../../../services/FileService";
import ClientService from "../../../services/ClientService";
import GroupService from "../../../services/GroupService";
import ToastContext, { ToastService } from "../bootstrap/Toast";
import { FileUploadResponse } from "../../file/entities/FileUploadResponse";
import React from "react";
import CompanyService from "../../../services/CompanyService";
import { endpointRequest } from "../../../utils/RequestUtils";
import { GroupListResponse } from "../../group/entities/GroupListResponse";
import { parseDate } from "../../../utils/ParseUtils";
import { Cast, MapNotUndefined } from "../../../utils/Utils";
import TooltipComponent from "../TooltipComponent";
import { CacheEntity, StorageService } from "../../../services/StorageService";

registerLocale("es", es);
registerLocale("en", en);
registerLocale("pt", pt);

export type EditorProps<T = string> = {
    onChange: (value: T) => void;
    defaultValue?: T;
};

export interface TextAreaEditorProps<T = string> extends EditorProps<T> {
    placeholder?: string;
    extraStyle?: React.CSSProperties;
    id?: string;
    rows?: number;
}

export interface DatePickerEditorProps<T = string> extends EditorProps<T> {
    showIcon?: boolean;
    placeholder?: string;
}

export const TextEditor = ({ onChange, defaultValue, value }: EditorProps & { value?: string }) =>
    <input type="text" onChange={event => onChange(event.target.value)}
        className="form-control" defaultValue={defaultValue} value={value} />;

export const TextEditorNumber = ({ onChange, defaultValue }: EditorProps) =>
    <input type="number" onChange={event => onChange(event.target.value)} className="form-control" defaultValue={defaultValue} />;

const validPhoneCharacters = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "+", "-", " ", "(", ")"];
function phoneOnInput(event: React.KeyboardEvent<HTMLInputElement>) {
    const data = event.key;
    if (data.length > 1 || validPhoneCharacters.includes(data)) {
        return;
    }
    event.preventDefault();
}

export const PhoneEditor = ({ onChange, defaultValue }: EditorProps) =>
    <input onChange={event => onChange(event.target.value)} className="form-control"
        defaultValue={defaultValue} onKeyDown={phoneOnInput} />;

export const TextAreaEditor = ({ onChange, defaultValue }: EditorProps) =>
    <textarea onChange={event => onChange(event.target.value)}
        className="form-control" rows={1} style={{ resize: "none" }} defaultValue={defaultValue} />;

export const TextAreaBigEditor = ({ onChange, defaultValue, placeholder, extraStyle, id, rows = 3 }: TextAreaEditorProps) =>
    <textarea
        autoFocus={true}
        onChange={event => onChange(event.target.value)}
        className="form-control" rows={rows} style={{ ...extraStyle }} defaultValue={defaultValue} placeholder={placeholder} />;


export const TextAreaXLEditor = ({ onChange, defaultValue }: EditorProps) =>
    <textarea onChange={event => onChange(event.target.value)}
        className="form-control" rows={6} style={{ resize: "none" }} defaultValue={defaultValue} />;


interface CheckboxEditorProps {
    onChange: (val: boolean) => void;
    message: string | React.ReactNode;
    defaultValue?: boolean;
    value?: boolean;
    disabled?: boolean;
    addClass?: string | React.ReactNode;
}

export const CheckBoxEditor = ({ onChange, message, defaultValue, value, disabled, addClass }: CheckboxEditorProps) => {
    return <div className="form-check pl-0">
        <label className={"form-check-label font-weight-normal " + addClass} >
            <input className="form-check-input me-2" type="checkbox" defaultChecked={defaultValue} checked={value} disabled={disabled} onChange={event => onChange(event.target.checked)} />
            {message}
        </label>
    </div>;
};

export const DatePickerEditor = ({ onChange, defaultValue, showIcon = false, placeholder = undefined }: DatePickerEditorProps) => {
    const [startDate, setStartDate] = useState(parseDate(defaultValue) ?? null);
    const { currentLanguage } = TranslationService;
    const dateFormat = currentLanguage === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy";

    return (<DatePicker dateFormat={dateFormat} className="form-control w-sm" selected={startDate}
        locale={currentLanguage}
        formatWeekDay={nameOfDay => nameOfDay.substring(0, 1).toUpperCase() + nameOfDay.substring(1, 3)}
        calendarStartDay={0}
        placeholderText={placeholder ?? dateFormat.toLocaleLowerCase()}
        timeInputLabel={TranslationService.translate.Hour}
        wrapperClassName={"d-block"}
        onChange={(date: Date | null) => {
            setStartDate(date);
            onChange(date?.toString() ?? "");
        }}
        showIcon={showIcon}
        icon={<i className="fa-regular fa-calendar text-secondary" style={{ top: 2 }}></i>}
    />
    );
};
type DateTimePickerEditorProps = EditorProps & { minDate?: Date };
export const DateTimePickerEditor = ({ onChange, defaultValue, minDate = undefined }: DateTimePickerEditorProps) => {
    const [startDate, setStartDate] = useState(MapNotUndefined(defaultValue, x => new Date(x)));
    const { currentLanguage } = TranslationService;
    const dateFormat = (currentLanguage === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy") + " HH:mm";
    return (<DatePicker dateFormat={dateFormat} showTimeInput timeIntervals={15} selected={startDate}
        wrapperClassName="d-block" className="form-control" timeFormat={"HH:mm"}
        formatWeekDay={nameOfDay => nameOfDay.substring(0, 1).toUpperCase() + nameOfDay.substring(1, 3)}
        calendarStartDay={0}
        minDate={minDate}
        timeInputLabel={TranslationService.translate.Hour}
        onChange={(date: Date | null) => {
            setStartDate(date);
            onChange(date ? date.toString() : "");
        }} locale={currentLanguage}
    />);
};

export const UserDropdownEditor = ({ onChange, defaultValue }: EditorProps) => {
    const { translate } = TranslationService;
    const getUserOptions = CompanyService.getUsers()?.map(x => ({ value: x.Id, text: x.Value ?? "", key: x.Id })) ?? [];
    return <Dropdown onChange={(x) => onChange(x ?? "")} defaultValue={defaultValue} items={getUserOptions} optionLabel={translate.None} />;
};

export const StatusDropdownEditor = ({ onChange, defaultValue, validStatus }: EditorProps<TaskStatus> & { validStatus?: TaskStatus[] }) => {
    const getStatusOptions = getStatusList().filter(x => !validStatus || validStatus.includes(x.status)).map(x => ({ value: x.status, text: TranslationService.getTranslation(x.translateKey) }));
    return <Dropdown onChange={onChange} defaultValue={defaultValue} items={getStatusOptions} />;
};

export const GroupCreatableEditor = React.forwardRef(function GroupGreatableEditorImpl({ onChange, defaultValue, personId }:
    EditorProps<GroupListResponse.Item> & { personId: number },
    ref: React.Ref<any>) {
    const { translate } = TranslationService;
    const [items, setItems] = useState<GroupListResponse.Item[]>([]);
    const creatableDefault = defaultValue?.GroupID === -1 ? { GroupID: -1, Name: defaultValue?.Name } : items.find(x => defaultValue?.GroupID);
    useEffect(() => {
        endpointRequest(() => GroupService.list(personId),
            x => setItems(x.list));
    }, [personId]);
    return (
        <Creatable
            ref={ref}
            isMulti={false}
            isClearable={true}
            classNamePrefix={"multiple-choice-select"}
            options={items}
            getOptionLabel={x => Cast<{ label?: string }>(x).label ?? x.Name}
            value={creatableDefault}
            onCreateOption={(value) => onChange({ GroupID: -1, Name: value } as GroupListResponse.Item)}
            onChange={(value) => onChange(value as GroupListResponse.Item)}
            placeholder={translate.Find}
            formatCreateLabel={(value: string) => `${translate.Create} "${value}"`}
        />
    );
});

type TagsDropdownEditorProps = EditorProps & { forceMultiple?: boolean, onlyIconTooltip?: boolean };

export const TagsDropdownEditor = ({ onChange, defaultValue, forceMultiple = undefined }: TagsDropdownEditorProps) => {
    const { translate } = TranslationService;
    const items = CompanyService.getTags()?.map(x => ({ value: x.Id, label: x.Value ?? "" })) ?? [];
    const defaultValuesSplit = defaultValue?.split(", ");
    const defaultVal: { value: string, label: string }[] = items.filter(x => defaultValuesSplit?.includes(x.value));

    if (forceMultiple ?? !CompanyService.getSetting("only1tag")) {
        return (
            <Creatable
                isMulti
                isClearable={false}
                classNamePrefix={"multiple-choice-select"}
                defaultValue={defaultVal}
                options={items}
                onChange={(values, meta) => onChange(values.map(x => x.value).join(", "))}
                placeholder={translate.SelectTags + "..."}
                formatCreateLabel={(value: string) => `${translate.Create} ${translate.Tag}: ${value}`}
            />
        );
    }
    else {
        return <Dropdown optionLabel={translate.Select} defaultValue={defaultValue} items={items.map(x => ({ value: x.value, text: x.label, key: x.value }))} onChange={(value) => onChange(value ?? "")}></Dropdown>;
    }
};

export const ActivityTaskDropdownEditor = ({ onChange, defaultValue }: EditorProps<string>) => {
    const { translate } = TranslationService;
    const items = CompanyService.getActivityTypes().filter(x => x.Task).map(x => ({ value: x.ActivityTypeID.toString(), text: x.ActivityTypeName }));
    return <Dropdown defaultValue={defaultValue ?? CompanyService.getActivityTypes().find(x => x.Task && x.TaskDefault)?.ActivityTypeID?.toString()}
        onChange={(x) => onChange(x ?? "")} items={items} optionLabel={translate.None} />;
};

export const AdditionalListDropdownEditor = ({ onChange, defaultValue, additionalDefinitionId }: EditorProps<number> & { additionalDefinitionId: number }) => {
    const { translate } = TranslationService;
    const items = CompanyService.getAdditionalDefinitions().find(x => x.AdditionalDefinitionID === additionalDefinitionId)!.AdditionalDefinitionItems.map(x => ({ value: x.AdditionalDefinitionItemID, text: x.Value }));
    return <Dropdown onChange={(x) => onChange(x ?? 0)} defaultValue={defaultValue} items={items} optionLabel={translate.None} />;
};

export class HasValidationEditorProps {
    constructor(
        public Editor: FC<EditorProps>,
        public onChange: (value: string) => void,
        public ValidationMessage: JSX.Element = <p className="text-danger">Error</p>,
        public isValid: (value: string | undefined) => boolean = x => !!x,
        public value?: string | undefined,
        public validationFunctionArray?: (() => boolean)[]
    ) { }
}

export const HasValidationEditor = (props: HasValidationEditorProps) => {
    const { isValid, onChange, validationFunctionArray } = props;
    const [value, setValue] = useState(props.value);
    const [showMessage, setShowMessage] = useState(false);
    const onValueChange = (newValue: string) => {
        setShowMessage(!isValid(newValue));
        onChange(newValue);
        setValue(newValue);
    };

    useEffect(() => {
        if (!validationFunctionArray) { return; }
        const validate = () => {
            const isValidValue = isValid(value);
            setShowMessage(!isValidValue);
            return isValidValue;
        };
        validationFunctionArray.push(validate);
        return () => { validationFunctionArray.splice(validationFunctionArray.indexOf(validate), 1); };
    }, [isValid, value, validationFunctionArray]);


    return <>
        {<props.Editor defaultValue={props.value} onChange={onValueChange} />}
        {showMessage && props.ValidationMessage}
    </>;
};

export interface FileApi {
    id: string;
    name: string;
}

export class FileListEditorParams {
    constructor(
        public fileUpload?: (file: globalThis.File) => Promise<FileUploadResponse | Error>,
        public onFilesChange?: (fileIdList: FileApi[]) => void,
        public fileDelete?: (id: string) => void,
        public downloadFile?: (id: string, name: string) => void,
        public files?: Array<{ id: string, name: string }>,
        public canEdit?: boolean,
        public variant?: boolean,
    ) { }
}

export const FileListEditor = (params: FileListEditorParams) => {
    const [files, setFiles] = useState<FileApi[]>(params.files ?? []);
    const [loading, setLoading] = useState(false);
    const { onFilesChange, fileDelete } = params;
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const downloadFile = params.downloadFile ?? FileService.download;
    const fileUpload = params.fileUpload ?? defaultFileUpload;
    const updateFiles = (files: FileApi[]) => {
        setFiles([...files]);
        if (onFilesChange) {
            onFilesChange(files);
        }
    };

    useEffect(() => {
        setFiles(params.files ?? []);
    }, [params.files]);

    const onFileChange = async (fileList: FileList | null) => {
        if (!(fileList?.length)) { return; }
        const file = fileList[0];
        if (file.size > 24000000) {
            showToast(translate.FileSizeError, undefined, "danger");
            return;
        }
        setLoading(true);
        const result = await fileUpload(file);
        setLoading(false);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        updateFiles([...files, { id: result.id, name: result.fileName }]);
    };

    const removeFile = (id: string) => {
        updateFiles([...files.filter(x => x.id !== id)]);
        if (fileDelete) { fileDelete(id); }
    };

    return (
        <>
            {files.length > 0 &&
                <ul className="list-unstyled my-2">
                    {files.map(x =>
                        <li key={x.id}>
                            <label className="form-label-detail pe-2">
                                {x.name}
                            </label>

                            <TooltipComponent title={translate.Download}>
                                <button className="btn btn-intiza px-2" onClick={() => downloadFile(x.id, x.name)}>
                                    <i className="far fa-file-arrow-down"></i>
                                </button>
                            </TooltipComponent>
                            {params.canEdit &&
                                <TooltipComponent title={translate.Delete}>
                                    <button className="btn btn-intiza px-2" onClick={() => removeFile(x.id)}>
                                        <i className="far fa-trash"></i>
                                    </button>
                                </TooltipComponent>
                            }
                        </li>
                    )}
                </ul>
            }
            {params.canEdit &&
                <label className="btn-intiza has-pointer text-start pl-0 removeOnPrint">
                    <input type="file" style={{ display: "none" }} onChange={(e) => onFileChange(e.target.files)} />
                    <i className="far fa-paperclip me-2"></i>
                    {translate.AttachFile}
                    {loading && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </label>}
        </>
    );
};


class FileSingleEditorParams {
    constructor(
        public fileUpload?: (file: globalThis.File) => Promise<FileUploadResponse | Error>,
        public onFileChange?: (file: FileApi | undefined) => void,
        public fileDelete?: (id: string) => void,
        public downloadFile?: (id: string, name: string) => void,
        public file?: { id: string, name: string },
        public canEdit?: boolean,
        public buttonText?: string | JSX.Element,
        public validExtensions?: string[],
        public invalidExtentionMessage?: string
    ) { }
}
export const defaultFileUpload = async (file: File) => {
    const result = await FileService.upload(file);
    if (result instanceof Error) {
        ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
        return result;
    }
    return { id: result.id, fileName: result.fileName };
};
export const FileSingleEditor = (params: FileSingleEditorParams) => {
    const [file, setFile] = useState<FileApi>();
    const [loading, setLoading] = useState(false);
    const { onFileChange, fileDelete } = params;
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);

    const downloadFile = params.downloadFile ?? FileService.download;
    const fileUpload = params.fileUpload ?? defaultFileUpload;
    const updateFile = (file: FileApi | undefined) => {
        setFile(file);
        if (onFileChange) {
            onFileChange(file);
        }
    };

    useEffect(() => {
        setFile(params.file);
    }, [params.file]);

    const onFileChanged = async (fileList: FileList | null) => {
        if (!(fileList?.length)) { return; }
        const file = fileList[0];
        if (file.size > 24000000) {
            showToast(translate.FileSizeError, undefined, "danger");
            return;
        }
        if (params.validExtensions && !params.validExtensions.find(x => file.name.endsWith(x))) {
            showToast(params.invalidExtentionMessage ?? translate.WarningFileExtention, undefined, "danger");
            return;
        }
        setLoading(true);
        const result = await fileUpload(file);
        setLoading(false);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        updateFile({ id: result.id, name: result.fileName });
    };

    const removeFile = (id: string) => {
        updateFile(undefined);
        if (fileDelete) { fileDelete(id); }
    };

    if (file) {
        return (
            <ul className="list-unstyled my-2">
                <li>
                    {file.name && file.name !== "" &&
                        <label className="form-label-detail pe-2">
                            {file.name}
                        </label>
                    }
                    <button className="btn btn-intiza px-2" onClick={() => downloadFile(file.id, file.name)}>
                        <i className="far fa-file-arrow-down"></i>
                    </button>
                    {params.canEdit &&
                        <button className="btn btn-intiza px-2" onClick={() => removeFile(file.id)}>
                            <i className="far fa-trash"></i>
                        </button>
                    }
                </li>
            </ul>
        );
    }
    else if (params.canEdit) {
        return (<label className="btn-intiza has-pointer text-start pl-0 removeOnPrint">
            <input type="file" style={{ display: "none" }} accept={params.validExtensions ? params.validExtensions.map(x => x.startsWith(".") ? x : "." + x).join(", ") : ""} onChange={(e) => onFileChanged(e.target.files)} />
            {params.buttonText ?? <><i className="far fa-paperclip me-2"></i>  {TranslationService.translate.AttachFile}</>}
            {loading && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
        </label>
        );
    }
    return (<></>);
};

function genericOptions<TRequestResult>(
    dataRequest: (inputValue: string) => Promise<Error | TRequestResult>,
    map: (result: TRequestResult) => { value: string, label: string }[],
    cache: Map<string, string>) {
    return async (inputValue: string) => {
        if (inputValue.length < 2) { return []; }
        const result = await dataRequest(inputValue);
        if (result instanceof Error) {
            return [];
        }
        const mapped = map(result);
        mapped.forEach(x => cache.set(x.value, x.label));
        return mapped;
    };
}
export const clientCache = new Map<string, string>();
const clientSelectOptions = genericOptions(ClientService.search, result => result.list.map(x => ({ value: x.id.toString(), label: x.name })), clientCache);

export const AutocompleteClient = (props: EditorProps) =>
    <AutocompleteSelect loadOptions={clientSelectOptions} onChange={(x) => {
        console.log({ x })
        if (x) {
            StorageService.setCache(CacheEntity.Client, x.value, x.label);
        }
        props.onChange(x?.value ?? "");
    }} />;

export const groupCache = new Map<string, string>();
const groupSelectOptions = (clientId: string | undefined) => genericOptions(
    async (input) => clientId ? await GroupService.search(clientId, input) : Error("No clientid"),
    result => result.list.map(x => ({ value: x.data.toString(), label: x.value })
    ), groupCache);

type AutocompleteGroupParams = Omit<AutocompleteSelectParams, "loadOptions"> & { clientId: string };

export const AutocompleteGroup = React.forwardRef(function AutocompleteGroupImpl(props: AutocompleteGroupParams, ref) {
    return (<AutocompleteSelect ref={ref} loadOptions={groupSelectOptions(props.clientId)} defaultVal={props.defaultVal} onChange={x => {
        if (x) {
            StorageService.setCache(CacheEntity.GroupName, x.value, x.label);
        }
        props.onChange(x);
    }} />);
});

export const AutocompleteCreateGroup = React.forwardRef(function AutocompleteGroupImpl(props: AutocompleteGroupParams, ref) {
    return (<AutocompleteCreateSelect ref={ref} loadOptions={groupSelectOptions(props.clientId)} defaultVal={props.defaultVal} onChange={x => {
        if (x) {
            StorageService.setCache(CacheEntity.GroupName, x.value, x.label);
        }
        props.onChange(x);
    }} />);
});

class AutocompleteSelectParams {
    constructor(
        public loadOptions: (inputValue: string) => Promise<{ value: string, label: string }[]>,
        public onChange: (value: { value: string, label: string } | undefined) => void,
        public defaultVal?: { value: string, label: string },
        public placeholder?: string,
    ) { }
}

export const AutocompleteSelect = React.forwardRef(function AutocompleteSelectImpl({ loadOptions, onChange, defaultVal, placeholder = "" }: AutocompleteSelectParams, ref: React.Ref<any>) {
    const { translate } = TranslationService;
    const SearchIcon = () => <i className="fal fa-search"></i>;
    const DropdownIndicator = (props: any) => <components.DropdownIndicator {...props}> <SearchIcon /> </components.DropdownIndicator>;

    return (
        <AsyncSelect
            ref={ref}
            defaultOptions
            placeholder={placeholder}
            isClearable={true}
            backspaceRemovesValue
            escapeClearsValue
            noOptionsMessage={() => translate.NoResults}
            loadingMessage={() => translate.Loading + "..."}
            defaultValue={defaultVal}
            loadOptions={loadOptions}
            classNamePrefix={"multiple-choice-select"}
            components={{ DropdownIndicator }}
            onChange={selected => onChange && onChange(selected ?? { label: "", value: "" })}
        />
    );
});

export const AutocompleteCreateSelect = React.forwardRef(function AutocompleteSelectImpl({ loadOptions, onChange, defaultVal, placeholder = "" }: AutocompleteSelectParams, ref: React.Ref<any>) {
    const { translate } = TranslationService;
    const SearchIcon = () => <i className="fal fa-search"></i>;
    const DropdownIndicator = (props: any) => <components.DropdownIndicator {...props}> <SearchIcon /> </components.DropdownIndicator>;

    return (
        <AsyncCreateSelect
            ref={ref}
            defaultOptions
            placeholder={placeholder}
            isClearable={true}
            backspaceRemovesValue
            escapeClearsValue
            formatCreateLabel={(value) => translate.Create + ' "' + value + '"'}
            noOptionsMessage={() => translate.NoResults}
            loadingMessage={() => translate.Loading + "..."}
            defaultValue={defaultVal}
            loadOptions={loadOptions}
            classNamePrefix={"multiple-choice-select"}
            components={{ DropdownIndicator }}
            onChange={selected => onChange && onChange(selected ?? { label: "", value: "" })}
        />
    );
});

class AutocompleteMultiSelectParams {
    constructor(
        public loadOptions: (inputValue: string) => Promise<{ value: string, label: string }[]>,
        public onChange: (value: readonly { value: string, label: string }[] | undefined) => void,
        public defaultVal?: { value: string, label: string }[],
    ) { }
}


export const AutocompleteMultiSelect = React.forwardRef(function AutocompleteMultiSelectImpl({ loadOptions, onChange, defaultVal }: AutocompleteMultiSelectParams, ref: React.Ref<any>) {
    const { translate } = TranslationService;
    const SearchIcon = () => <i className="fal fa-search"></i>;
    const DropdownIndicator = (props: any) => <components.DropdownIndicator {...props}> <SearchIcon /> </components.DropdownIndicator>;
    const settedDefault = useRef(false);
    return (
        <AsyncSelect
            ref={x => {
                if (!x) {
                    return;
                }
                if (!settedDefault.current) {
                    settedDefault.current = true;
                    x?.setValue(defaultVal ?? [], "select-option");
                }
                if (typeof ref === "function") {
                    ref(x);
                }
            }}
            isMulti
            placeholder=""
            isClearable={true}
            noOptionsMessage={() => translate.NoResults}
            loadingMessage={() => translate.Loading + "..."}
            loadOptions={loadOptions}
            classNamePrefix={"multiple-choice-select"}
            components={{ DropdownIndicator }}
            onChange={selected => onChange && onChange(selected ?? [])}
        />
    );
});

class MultiselectParams {
    constructor(
        public items: { value: string, label: string }[],
        public defaultValue?: { value: string, label: string }[],
        public value?: { value: string, label: string }[],
        public callback?: (value: string[] | undefined) => void,
        public className?: string,
        public placeholder?: string
    ) { }
}

export const MultiselectEditor = ({ items, defaultValue, value, placeholder, callback, className = undefined }: MultiselectParams) => {
    const { translate } = TranslationService;
    defaultValue = defaultValue ?? [];

    return (
        <Select
            isMulti
            isClearable={false}
            defaultValue={defaultValue}
            value={value}
            options={items}
            onChange={(values) => { if (callback) { callback(values.map(x => x.value)); } }}
            placeholder={placeholder ?? translate.None}
            classNamePrefix={"multiple-choice-select"}
            className={className}
        />
    );
};

export class EditShowFormProps {
    constructor(
        public title: string,
        public value: string | undefined,
        public Editor: FC<EditorProps>,
        public onSave: (value: string | undefined) => void,
        public formatValue: (value: string | undefined) => string | undefined = x => x,
        public showClearBtn: boolean = false,
        public isEditing: boolean = false,
        public setIsEditing: ((isEditing: boolean) => void) | undefined = undefined,
        public emptyValidation: boolean = false,
        public EmptyValidationMessage?: JSX.Element,
    ) { }
}

export const EditShowForm = ({ title, value, formatValue, Editor, onSave, showClearBtn, isEditing, setIsEditing, emptyValidation, EmptyValidationMessage }: EditShowFormProps) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [editingValue, setEditingValue] = useState(value);

    const errorShow = useMemo(() =>
        emptyValidation && isEditing && (!editingValue?.length || !currentValue?.length)
        , [editingValue?.length, emptyValidation, isEditing, currentValue?.length])

    const clear = () => {
        setEditingValue(undefined);
        saveValue(undefined);
    };

    const saveValue = (value: string | undefined) => {
        setCurrentValue(value);
        setIsEditing && setIsEditing(false);
        onSave(value);
    };

    const save = () => {
        if (emptyValidation && !editingValue?.length) {
            return
        }
        saveValue(editingValue);
    };
    return (
        <div className="d-flex mb-3 me-3">
            <label className="col form-label">
                {title}
            </label>
            <div className="show-child-on-hover col ml-auto align-items-center">
                <div className="col">
                    {!isEditing &&
                        <div className="d-flex justify-content-end gap-2">
                            <div className="col-auto text-end">
                                <i className={"pointer hideOnPrint far fa-pen show-when-hovering-parent"}
                                    onClick={() => setIsEditing && setIsEditing(!isEditing)} />
                            </div>
                            <div className="col-auto">
                                <p className="form-label-detail">
                                    {currentValue === "" || !currentValue ? "-" : formatValue(currentValue)}
                                </p>
                            </div>
                        </div>}
                    {/* {isEditing &&
                        <div className="col-auto">
                            {showClearBtn &&
                                <button type="button" className="btn btn-small btn-link" onClick={clear}>
                                    {translate.Clear}
                                </button>
                            }
                            <button type="button" className="btn btn-small btn-success h-40px" onClick={save}>{translate.Save}</button>
                        </div>} */}
                    {isEditing &&
                        <div className="row">
                            <div className="col p-0">
                                <Editor defaultValue={currentValue} onChange={setEditingValue} />
                            </div>

                            <div className="col-auto d-flex align-items-center">
                                {showClearBtn ?
                                    <i className="fa-fw fa-solid fa-times ms-1 text-danger pointer"
                                        onClick={clear} /> :
                                    <i className={"far fa-times text-danger ms-2 text-primary pointer"}
                                        onClick={() => setIsEditing && setIsEditing(!isEditing)} />}
                                <i className="fa-fw fa-solid fa-check ms-2 text-primary pointer"
                                    onClick={save} />
                            </div>
                        </div>}

                </div>
                {errorShow ? EmptyValidationMessage : null}
            </div>
        </div >
    );
};

export const EditShowFormReadOnly = ({ title, value, formatValue }: EditShowFormProps) => {
    return (
        <div className="d-flex input-row mb-3">
            <label className="form-label">
                {title}
            </label>
            <p className="form-label-detail px-3">
                {value === "" || !value ? "-" : formatValue(value)}
            </p>
        </div>);
};


