import React, { useContext, useEffect, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import ButtonPrimary from "../../account/components/ButtonPrimary";
import * as yup from "yup";
import Dropdown from "../../shared/components/Dropdown";
import { CheckBoxEditor } from "../../shared/components/Editors";
import ButtonSecondary from "../../account/components/ButtonSecondary";
import { TitleConfig } from "./components/TitleConfig";
import FileService from "../../../services/FileService";
import ToastContext, { ToastService } from "../../shared/bootstrap/Toast";
import CompanyService from "../../../services/CompanyService";
import { handleErrorWithToast } from "../../../utils/RequestUtils";

type BackupConfigurationType = {
    backupType?: number;
    backupPeriod?: number;
    backupMonthDay?: number;
    backupDays?: number;
    lastBackup?: string;
    // weeks
    backupDay1?: boolean;
    backupDay2?: boolean;
    backupDay3?: boolean;
    backupDay4?: boolean;
    backupDay5?: boolean;
    backupDay6?: boolean;
    backupDay0?: boolean;
    backupNotifyTo?: string;
    backupDestination: string;
}

export const BackupConfiguration = ({
    backupType = 0,
    backupPeriod = 1,
    backupMonthDay = 22,
    backupDays = 60,
    lastBackup = "18/07/2023",
    // weeks
    backupDay1 = false,
    backupDay2 = false,
    backupDay3 = false,
    backupDay4 = false,
    backupDay5 = false,
    backupDay6 = false,
    backupDay0 = false,
    backupNotifyTo = "",
    backupDestination,
}: BackupConfigurationType) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);

    const [error, setError] = useState("");
    const [saveData, setSaveData] = useState(false);
    const [backupRequested, setBackupRequested] = useState(false);
    const [backupRequest, setBackupRequest] = useState({ days: 0, type: 0 });
    const [formData, setFormData] = useState({
        backupType: parseInt(backupType?.toString() ?? 0),
        backupPeriod: parseInt(backupPeriod?.toString() ?? 0),
        backupMonthDay,
        backupDays,
        backupDay0,
        backupDay1,
        backupDay2,
        backupDay3,
        backupDay4,
        backupDay5,
        backupDay6,
        backupDestination,
        backupNotifyTo,
    });

    const OPTIONS_BACKUP = [
        { value: 0, text: TranslationService.getTranslation("PartialBackup") },
        { value: 1, text: TranslationService.getTranslation("OnlyPendingAnd60DaysOfInteractions") },
        { value: 2, text: TranslationService.getTranslation("FullBackup") },
    ];

    const OPTIONS_PERIOD = [
        { value: 0, text: TranslationService.getTranslation("Weekly") },
        { value: 1, text: TranslationService.getTranslation("Monthly") },
    ];

    const WEEKS = [
        { week: backupDay0, weekText: "WeekSunday", weekProp: "backupDay0" },
        { week: backupDay1, weekText: "WeekMonday", weekProp: "backupDay1" },
        { week: backupDay2, weekText: "WeekTuesday", weekProp: "backupDay2" },
        { week: backupDay3, weekText: "WeekWednesday", weekProp: "backupDay3" },
        { week: backupDay4, weekText: "WeekThursday", weekProp: "backupDay4" },
        { week: backupDay5, weekText: "WeekFriday", weekProp: "backupDay5" },
        { week: backupDay6, weekText: "WeekSaturday", weekProp: "backupDay6" },
    ] as const;

    const formDataSchema = yup.object({});

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSelect = <T extends keyof typeof formData,>(valueUpdated: (typeof formData)[T], name: T) => {
        setFormData((prevState) => ({ ...prevState, [name]: valueUpdated }));
    };

    const handleRequestBackup = async () => {
        await handleErrorWithToast(CompanyService.requestBackup(backupRequest));
        setBackupRequested(true);
    };

    const handleDownloadLastBackup = () => {
        FileService.downloadLastBackup()
            .then((result) => {
                if (result instanceof Error) {
                    showToast(translate.ErrorProcessingRequest, undefined, "danger");
                    return result;
                }
            });
    };

    const saveValues = async () => {
        await handleErrorWithToast(CompanyService.setBackupSchedule({
            scheduleBackupType: formData.backupType,
            period: formData.backupPeriod,
            scheduleBackupDays: formData.backupDays,
            day0: formData.backupDay0.toString().toLocaleLowerCase() === "true" ? 1 : 0,
            day1: formData.backupDay1.toString().toLocaleLowerCase() === "true" ? 1 : 0,
            day2: formData.backupDay2.toString().toLocaleLowerCase() === "true" ? 1 : 0,
            day3: formData.backupDay3.toString().toLocaleLowerCase() === "true" ? 1 : 0,
            day4: formData.backupDay4.toString().toLocaleLowerCase() === "true" ? 1 : 0,
            day5: formData.backupDay5.toString().toLocaleLowerCase() === "true" ? 1 : 0,
            day6: formData.backupDay6.toString().toLocaleLowerCase() === "true" ? 1 : 0,
            backupnotifyto: formData.backupNotifyTo,
            backupDestination: formData.backupDestination,
            day: formData.backupMonthDay,
        }));
        ToastService.showToast(translate.SavedChanges, undefined, "success");
    };

    useEffect(() => {
        formDataSchema.validate(formData)
            .then(() => {
                setError("");
                setSaveData(true);

            })
            .catch((err) => setError(err.errors[0]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    return (
        <div className='col-md-10'>
            <div className='col-md-10 mx-3'>
                <TitleConfig title={translate.Backup} />
                {backupRequested
                    ? <TitleConfig title='Tu solicitud fue recibida. Te enviaremos un email cuando la copia de respaldo esté generada.' />
                    :
                    <>
                        <div className='col-sm-11 d-flex justify-content-between align-items-center mb-4 gap-2'>
                            <div className='col-sm-5'><Dropdown items={OPTIONS_BACKUP} onChange={(value) => setBackupRequest(y => ({ ...y, type: value }))} /></div>
                            {backupRequest.type === 0 &&
                                <div className="col-3 d-flex align-items-center">
                                    <div className="mx-2 text-end">{translate.Including}</div>
                                    <input name='backupDays' onChange={(e) => setBackupRequest(y => ({ ...y, days: e.target.valueAsNumber }))} type="number" className="form-control form-control-sm" aria-describedby="basic-addon1" />
                                    <div className=" mx-2 text-end">{translate.Days}</div>
                                </div>}
                            <div className='col-sm-4'>
                                <ButtonPrimary onClick={() => { handleRequestBackup(); }}>{translate.RequestBackup}</ButtonPrimary>
                            </div>
                        </div>
                    </>}
                <hr />
                <TitleConfig title={translate.ScheduleBackupCopy} />
                <div className='col-md-11 d-flex align-items-center mb-4 gap-2 row'>
                    <div className='col-md-5'><Dropdown items={OPTIONS_BACKUP} onChange={(value) => handleSelect(value, "backupType")} defaultValue={backupType} /></div>
                    <div className="col-4 row align-items-center">
                        {formData.backupType === 0 && <>
                            <div className="text-end col-auto">{translate.Including}</div>
                            <input name='backupDays' defaultValue={formData.backupDays} onChange={handleInputChange} type="number" className="form-control form-control-sm col" aria-describedby="basic-addon1" />
                            <div className="text-end col-auto">{translate.Days}</div>
                        </>}
                    </div>
                </div>
                <div className='col-md-11 d-flex align-items-center mb-4 gap-2'>
                    <div className='col-md-4'><Dropdown items={OPTIONS_PERIOD} onChange={(value) => handleSelect(value, "backupPeriod")} defaultValue={backupPeriod} /></div>
                    {formData.backupPeriod === 1 && <div className="col-md-3  d-flex  align-items-center">
                        <div className=" mx-2 text-end">{translate.OnDays}</div>
                        <input name='backupMonthDay' className="col form-control form-control-md" defaultValue={formData.backupMonthDay} onChange={handleInputChange} type="number" aria-describedby="basic-addon1" />
                    </div>}
                </div>
                {formData.backupPeriod === 0 && <div className='d-flex col justify-between mb-3'>
                    {WEEKS.map(({ weekProp, weekText }) =>
                        <div className='col' key={weekProp}>
                            <CheckBoxEditor
                                onChange={value => handleSelect(value, weekProp)}
                                message={TranslationService.getTranslation(weekText)}
                                defaultValue={formData[weekProp].toString().toLocaleLowerCase() === "true"}
                            />
                        </div>
                    )}
                </div>}
                <div className='col-md-11 d-flex align-items-center mb-4 gap-2'>
                    <div className="col-md-8  d-flex  align-items-center">
                        <div className="me-2 text-end">{translate.NotifyTo}</div>
                        <input name='backupNotifyTo' className="col form-control form-control-md" defaultValue={formData.backupNotifyTo} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="align-items-center input-group mb-3">
                    <ButtonSecondary onClick={handleDownloadLastBackup}>{translate.DownloadLastBackup}</ButtonSecondary>
                    <div className=" mx-2 text-end">({lastBackup})</div>
                </div>
            </div>
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div id="errorMsj" className="incorrect" style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>{error}</div>
                    {saveData && !error && <ButtonPrimary onClick={() => { saveValues(); }}>{translate.Save}</ButtonPrimary>}
                </div>
            </div>
        </div>
    );
};
