import { useContext, useEffect, useMemo, useState } from "react";
import { capitalize, formatDateDigits, formatInteger } from "../../../utils/FormatUtils";
import { DataType } from "../../../services/CompanyService";
import { AdvancedFiltersContext } from "../../shared/components/AdvancedFilters";
import { PortalClientDataRequest } from "../../../services/PortalClientService";
import { SortDirection } from "../../shared/entities/Sort";
import { TranslationService } from "../../../services/TranslationService";
import Loading from "../../shared/components/Loading";
import ExportPortalService from "../../../services/ExportPortalService";
import PaginatorPortal from "../../shared/PaginatorPortal";

type HeaderType = {
    columnId: string,
    dataType: DataType,
    columnTitle: string,
}

type RowType = { Key: string, Value: number | string }

export const PortalInvoicesTable = ({
    response,
    selectedRows,
    setSelectedRows,
    selectAll,
    setSelectAll,
    setRequest,
    request,
    isLoading = true,
    selectedInvoices,
    setSelectedInvoices,
    tokenId
}: {
    response: any,
    selectedRows: number[],
    setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>,
    selectAll: boolean,
    setSelectAll: React.Dispatch<React.SetStateAction<boolean>>,
    setRequest: (request: PortalClientDataRequest) => void,
    request: PortalClientDataRequest,
    isLoading: boolean,
    selectedInvoices: { id: number, nro: string }[],
    setSelectedInvoices: React.Dispatch<React.SetStateAction<{ id: number, nro: string }[]>>,
    tokenId: string
}) => {
    const { headers = [], rowsToShow, currencyTotals } = response.data;
    const tableHeaders: HeaderType[] = headers;
    const tableHeadersWithAction: HeaderType[] = useMemo(() => {
        const headerAction: HeaderType = { columnId: 'download', columnTitle: 'Descargar', dataType: DataType.Text };
        if (tableHeaders.length > 0) {
            const auxArr = [...tableHeaders, headerAction];
            return auxArr;
        } else {
            return [];
        }
    }, [tableHeaders])

    const totalRows = Object.keys(currencyTotals);
    const advancedFiltersContext = useContext(AdvancedFiltersContext);
    const [sortOrder, setSortOrder] = useState<{ [key: string]: SortDirection.Descending | SortDirection.Ascending | undefined }>({});

    useEffect(() => {
        advancedFiltersContext.setShow(true);
    }, [advancedFiltersContext]);

    const toggleSortOrder = (columnId: string) => {
        let order: Partial<PortalClientDataRequest> = {};
        const newOrder = sortOrder[columnId] === SortDirection.Ascending ? SortDirection.Descending : sortOrder[columnId] === SortDirection.Descending ? undefined : SortDirection.Ascending;
        order.sortColumn = newOrder === undefined ? undefined : tableHeadersWithAction.find(h => h.columnId === columnId)?.columnTitle;
        order.descending = newOrder;
        setRequest({ ...request, ...order })
        setSortOrder({ [columnId]: newOrder });
    };

    const handleRowSelect = (id: number, nro: string) => {
        setSelectedRows(prevSelectedRows =>
            prevSelectedRows.includes(id)
                ? prevSelectedRows.filter(i => i !== id)
                : [...prevSelectedRows, id]
        );
        setSelectedInvoices(prevSelectedRows =>
            prevSelectedRows.map(x => x.id).includes(id)
                ? prevSelectedRows.filter(z => z.id !== id)
                : [...prevSelectedRows, { id, nro }]
        );
    };

    const handleSelectAll = () => {
        if (!selectAll) {
            setSelectAll(true);
            setSelectedRows(rowsToShow.map((row: any) => {
                const keyV = row.find((el: any) => el.Key === 'ioid')?.Value;
                return keyV;
            }));
            setSelectedInvoices(rowsToShow.map((row: any) => {
                const fcV = row.find((el: any) => el.Key === '-4')?.Value;
                const keyV = row.find((el: any) => el.Key === 'ioid')?.Value;
                return { id: keyV, nro: fcV }
            }));
        } else {
            setSelectAll(false);
            setSelectedRows([]);
            setSelectedInvoices([])
        }
    };
    return (
        <div className="tableContainer overflow-x-hidden">
            {Boolean(response.data.count) ?
                <>
                    <div className="table-responsive px-md-3">
                        <table className="table bg-white tableContainerPublicO mx-1 my-2 m-md-0">
                            <thead>
                                <tr>
                                    <th style={{ maxWidth: 50, minWidth: 50 }}>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            style={{ top: 2, position: 'relative' }}
                                        />
                                    </th>
                                    {tableHeadersWithAction?.map((header, i) => (
                                        <HeaderValue
                                            key={i}
                                            header={header}
                                            sortOrder={sortOrder[header.columnId]}
                                            onClick={() => toggleSortOrder(header.columnId)}
                                        />
                                    ))}
                                </tr>
                            </thead>
                            {!isLoading &&
                                <tbody className="overflow-x">
                                    {rowsToShow.map((row: any, i: number) => {
                                        const fcV = row.find((el: any) => el.Key === '-4')?.Value;
                                        const keyV = row.find((el: any) => el.Key === 'ioid')?.Value;
                                        const isDue = row.find((el: any) => el.Key === 'paidStatus')?.Value === 2;
                                        const hasFiles = row.find((el: any) => el.Key === 'hasFiles')?.Value;
                                        return (
                                            <tr key={i}>
                                                <td style={{ maxWidth: 50, minWidth: 50, }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedRows.includes(keyV)}
                                                        onChange={() => handleRowSelect(keyV, fcV)}
                                                        style={{ top: 2, position: 'relative' }}
                                                    />
                                                </td>
                                                {tableHeadersWithAction?.map((header, id) => {

                                                    const rowToShow: any = (header.columnId === 'download') ?
                                                        { Key: 'download', Value: keyV } :
                                                        row.find((r: any) => r.Key === header.columnId);
                                                    const currency = header.dataType === DataType.Currency
                                                        ? row.find((r: any) => r.Key === "currencySymbol")?.Value.toString()
                                                        : "";
                                                    return rowToShow ? (
                                                        <ItemValue
                                                            key={id}
                                                            row={rowToShow}
                                                            header={header}
                                                            currency={currency || ""}
                                                            isDue={isDue}
                                                            hasFiles={hasFiles}
                                                            tokenId={tokenId}
                                                        />
                                                    ) : null;
                                                })}
                                            </tr>
                                        )
                                    }
                                    )}
                                    {totalRows?.map((ct, k) => {
                                        const dataCurrency = currencyTotals[ct];
                                        const simbol = ct?.split(", ")[1].replace(")", "");
                                        return (
                                            <tr key={k} style={{ backgroundColor: "#ddedf9" }}>
                                                <td></td>
                                                {tableHeadersWithAction?.map((header, i) => {
                                                    const hideSymbol = header.dataType !== DataType.Currency;
                                                    const rowToShow = dataCurrency.find(
                                                        (r: any) => r.Item1.toString() === header.columnId.toString()
                                                    );
                                                    return (
                                                        <td key={i}>
                                                            <div className='text-end'>
                                                                {rowToShow?.Item2 >= 0 ?
                                                                    (hideSymbol ? '' : simbol) + formatInteger(parseInt(rowToShow.Item2)) :
                                                                    ""}
                                                            </div>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>}
                        </table>
                        {isLoading &&
                            <div
                                className="d-flex justify-content-center align-items-center mh-100 p-0"
                                style={{ backgroundColor: "#f2f1f6", minHeight: "calc(100vh - 179px)" }}
                            >
                                <Loading />
                            </div>}
                    </div>
                    <div className="container my-4">
                        <PaginatorPortal />
                    </div>
                </>
                :
                <nav className="d-flex justify-content-between align-items-center mt-4" aria-label="Paginación">
                    <p className="col text-center">{TranslationService.translate.NoDataToShow}</p>
                </nav>
            }
        </div>
    );
}

const HeaderValue = ({
    header,
    sortOrder,
    onClick
}: {
    header: HeaderType,
    sortOrder: SortDirection.Ascending | SortDirection.Descending | undefined,
    onClick: () => void
}) => {

    const endClass = alignByType(header);
    return (
        <th onClick={onClick} style={{ cursor: 'pointer' }}>
            <div className={"d-flex " + endClass}>
                <div>
                    {capitalize(header.columnTitle)}
                </div>
                <div className="ms-1">
                    {sortOrder === SortDirection.Ascending ? '▲' : sortOrder === SortDirection.Descending ? '▼' : undefined}
                </div>
            </div>
        </th>
    );
};

const alignByType = (header: HeaderType): string => {
    const isCurrency = header.dataType === DataType.Currency;
    const isNumber = header.dataType === DataType.Number;
    const isDueDate = (header.columnId === "-5" || header.columnTitle === "Vencimiento")
    const isDownload = header.columnId === "download";
    return (isNumber || isCurrency || isDueDate) ?
        'text-end  align-items-end justify-content-end' :
        isDownload ?
            'text-center  align-items-center justify-content-center' :
            'text-start  align-items-start justify-content-start';
};

const ItemValue = ({ row, header, currency = '', isDue = false, hasFiles = false, tokenId }: { row: RowType, header: HeaderType, currency: string, isDue: boolean, hasFiles: boolean, tokenId: string }) => {
    let endClass = alignByType(header);
    const valueRow = row?.Value ? row.Value.toString() : '';
    let dataRow: any = valueRow;
    const isDueValue = dataRow && isDue && (header.columnId === "-5" || header.columnTitle === "Vencimiento");
    const isDownload = header.columnId === "download";

    const downloadFile = async () => {
        await ExportPortalService.requestExport("/customer/downloadiofiles", {
            ioID: row.Value.toString()
        }, 1, 'customer_list.xlsx', tokenId);
    };


    if (header.dataType === DataType.Number) {
        dataRow = formatInteger(parseInt(valueRow || '0'));
    } else if (header.dataType === DataType.Currency && currency) {
        dataRow = currency + ' ' + formatInteger(parseInt(valueRow || '0'));
    } else if (header.dataType === DataType.Date) {
        dataRow = valueRow ? formatDateDigits(new Date(valueRow)) : '';
    } else if (header.dataType === DataType.Link && valueRow.length > 0) {
        dataRow = <a href={valueRow} target='_blank noreferrer'>Link</a>;
    } else if (row.Key === 'paidStatus') {
        switch (valueRow) {
            case '0':
            case '':
                dataRow = 'Pendiente';
                break;
            case '1':
                dataRow = 'Completado';
                break;
            case '2':
                dataRow = 'Vencido';
                endClass = ' dueStatus'
                break;
            default:
                dataRow = valueRow;
                break;
        }
    }

    return <td className={endClass}>
        {isDueValue ?
            <div><span className="boxDueDate">{dataRow}</span></div> :
            isDownload ?
                <div><span className="pointer" onClick={downloadFile} >{hasFiles ? <i className="fa fa-download" /> : ''}</span></div> :
                dataRow
        }
    </td>
};
