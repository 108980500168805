import TooltipComponent from "../TooltipComponent";

type ButtonTooltipIconType = {
    title: string,
    icon: string,
    onClick: () => void,
    colorIcon?:
    "text-primary" |
    "text-secondary",
    isLink?: boolean,
    style?: any
}

export const ButtonTooltipIcon = ({
    title,
    icon,
    onClick,
    colorIcon = "text-secondary",
    isLink = true,
    style
}: ButtonTooltipIconType) => {
    return (
        <TooltipComponent title={title}>
            <button type="button" className={"btn " + colorIcon + " " + (isLink && " btn-link ")} style={style} onClick={onClick}>
                <i className={icon + " " + (isLink ? " text-primary " : colorIcon)}></i>
            </button>
        </TooltipComponent>
    );
};


