import RequestPortalService from "./RequestPortalService";

export type MultiuploadResultDataType = {
    data: {
        files: {
            id: string; filename: string;
        }[];
    }
}

class FilePortalService {
    public static async download(fileId: string, fileName: string = "export.xlsx") {
        return RequestPortalService.downloadFile("/file/download?id=" + fileId, fileName);
    }
    public static multiupload(files: File[], token: string): Promise<MultiuploadResultDataType | Error> {
        const formData = new FormData();
        files.forEach(x => formData.append("File", x, x.name));
        return RequestPortalService.post("/customer/fileupload", formData, token);
    }
}

export default FilePortalService;