import { onClearCache } from "../pages/shared/Logout";
import { LangIndex, TranslationService } from "../services/TranslationService";


function getOrElse<T>(key: string, defaultValue: T): T {
    try {
        const raw = localStorage.getItem(key);
        const data = raw ? JSON.parse(raw) : "";
        if (!data) {
            return defaultValue;
        } else {
            return data;
        }
    } catch (error) {
        return defaultValue;
    }
}

function get<T>(key: string): T | undefined {
    return getOrElse(key, undefined);
}

function set<T>(key: string, value: T): void {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error("Error setting value in local storage.");
    }
}

function deleteEntry(key: string): void {
    localStorage.removeItem(key);
}

const cacheKeys = ["company_auth", "company_token", "user_payload", "authentication_data", "company_auth_portal"] as const;
const cache_base_key = "company_cache_";
type CacheKeys = typeof cacheKeys[number];
const cacheRepo = {} as Record<CacheKeys, any>;
export function getCache<T>(key: CacheKeys): Readonly<T> | undefined {
    cacheRepo[key] = cacheRepo[key] || get<T>(cache_base_key + key);
    return cacheRepo[key] as T;
}

export function setCache(key: CacheKeys, value: unknown) {
    cacheRepo[key] = value;
    set(cache_base_key + key, value);
}

export function clearCache() {
    for (const key of cacheKeys) {
        deleteEntry(cache_base_key + key);
    }
    Object.keys(cacheRepo).forEach(x => cacheRepo[x as keyof typeof cacheRepo] = undefined);
}
export { getOrElse, set, get, deleteEntry };

type TupleToNestedObject<T, U> = T extends [infer F, ...infer R] ? {
    [K in F & (string | number | symbol)]: TupleToNestedObject<R, U>
} : U

/**
 * In memory cache for objects that depend on the language
 */
export class LanguageCache<TGenerator extends (...params: any) => any> {
    public cache: LangIndex<TupleToNestedObject<Parameters<TGenerator>, ReturnType<TGenerator> | undefined> | undefined> = { en: undefined, es: undefined, pt: undefined };
    constructor(private generator: TGenerator) {
        onClearCache.subscribe(() => this.cache = { en: undefined, es: undefined, pt: undefined });
    }
    get = (...params: Parameters<TGenerator>): ReturnType<TGenerator> => {
        const getOrSetKey = <T extends object,>(map: T, key: keyof T, generator: () => any = () => ({})) => {
            return map[key] !== undefined ? map[key] : map[key] = generator();
        };
        const curry = () => this.generator.apply(null, params);
        if (params.length) {
            let value = getOrSetKey(this.cache, TranslationService.currentLanguage);
            for (let param = 0; param < params.length; param++) {
                const element = params[param];
                value = getOrSetKey(value, element, (params.length - 1 === param) ? curry : undefined);
            }
            return value;
        } else {
            return getOrSetKey(this.cache, TranslationService.currentLanguage, curry);
        }
    };
}