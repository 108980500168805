import TooltipComponent from "../TooltipComponent";
import downloadExport from '../../../assets/img/download-export.svg'
import filterPublic from '../../../assets/img/filterPublic.svg'
import importPublic from '../../../assets/img/importPublic.svg'


type ButtonIconType = {
    title: string,
    onClick: () => void,
    style?: any,
    typeIcon?: 'filter' | 'download' | 'import'
}

export const ButtonIcon = ({
    title,
    onClick,
    style,
    typeIcon
}: ButtonIconType) => {
    let icon = downloadExport;

    switch (typeIcon) {
        case 'download':
            icon = downloadExport
            break;
        case 'filter':
            icon = filterPublic;
            break;
        case 'import':
            icon = importPublic
            break;
        default:
            icon = downloadExport
            break;
    }

    return (
        <TooltipComponent title={title}>
            <div className="btnIconWrap pointer" style={style} onClick={onClick}>
                <img src={icon} alt={typeIcon} />
            </div>
        </TooltipComponent>
    );
};


