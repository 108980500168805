import React, { useCallback, useMemo, useState } from "react";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { applyFiltersHelper, setCurrentPageHelper, setPageSizeHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";
import PortalClientService, { PortalClientDataRequest } from "../../../services/PortalClientService";

export class PortalClientTableContextValues extends TableContextValues<any> {
    request: PortalClientDataRequest = new PortalClientDataRequest();
    setRequest: (request: PortalClientDataRequest) => void = () => { };
}

const PortalClientTableProvider = ({ token, children }: React.PropsWithChildren<{ token: string }>) => {
    const [request, setRequest] = useState(new PortalClientDataRequest());
    const endpoint = useCallback(() => PortalClientService.getIoList(request, token), [request, token]);
    const { loading, error, response, reload } = useEndpointData<any>(endpoint);
    const { sort, setSortColumn } = sortHelper(request, setRequest);
    const pageCount = useMemo(() => response?.data ? Math.ceil((response.data.count) / response.data.pagesize) : 0, [response?.data])

    const value: PortalClientTableContextValues = {
        loading,
        error,
        response,
        setSortColumn,
        currentPage: request.page,
        setCurrentPage: response?.data ? setCurrentPageHelper(setRequest, response.data.count) : () => { },
        pageCount,
        applyFilters: applyFiltersHelper(setRequest),
        applySearch: (keyword: any) => { setRequest({ ...request, filter: keyword }) },
        reload,
        sort,
        pageSize: request.pagesize,
        setPageSize: setPageSizeHelper(setRequest),
        request,
        setRequest,
    }

    return (
        <TableContext.Provider value={value}>
            {children}
        </TableContext.Provider>
    )
}

export default PortalClientTableProvider; 