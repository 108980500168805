import { useState, useEffect, useRef } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { ToastService } from "../bootstrap/Toast";
import { FileApi, FileListEditorParams } from "./Editors";
import { DomEventService } from "../../../services/DomEventService";
import { FilesAttached } from "../../client/activity/components/NewComment";
import { ButtonTooltipIcon } from "./ButtonTooltipIcon";
import FilePortalService, { MultiuploadResultDataType } from "../../../services/FilePortalService";
import FileService, { MultiuploadResultType } from "../../../services/FileService";

type PortalTokenType = { token?: string }
export const MultiFileUpload = (params: FileListEditorParams & PortalTokenType) => {
    const [files, setFiles] = useState<FileApi[]>([]);
    const [showDrag, setShowDrag] = useState(false);
    const [loading, setLoading] = useState(false);
    const dropRef = useRef<HTMLDivElement | null>(null);
    const { onFilesChange, fileDelete, variant, token = '' } = params;
    const downloadFile = params.downloadFile ?? FileService.download;
    const updateFiles = (files: FileApi[]) => {
        setFiles([...files]);
        if (onFilesChange) {
            onFilesChange(files);
        }
    };

    const dragLeaveTimeout = useRef<number>(0);
    useEffect(() => {
        const subscriptions = [
            DomEventService.GetBodyEvents("dragover").subscribe(x => {
                if (x.dataTransfer?.types.includes("Files")) {
                    x.preventDefault();
                    clearTimeout(dragLeaveTimeout.current);
                    setShowDrag(true);
                }
            }),
            DomEventService.GetBodyEvents("dragleave").subscribe(x => {
                if (x.dataTransfer?.types.includes("Files")) {
                    clearTimeout(dragLeaveTimeout.current);
                    dragLeaveTimeout.current = setTimeout(() => setShowDrag(false), 100, undefined);
                }
            }),
            DomEventService.GetBodyEvents("drop").subscribe(x => {
                if (x.dataTransfer?.types.includes("Files")) {
                    if (x.target === dropRef.current) {
                        x.preventDefault();
                        const files = [...x.dataTransfer.items]
                            .map(item =>
                                item.kind === "file" ? item.getAsFile() : undefined
                            )
                            .filterFalsey();
                        if (files.length === 0) {
                            files.push(...x.dataTransfer.files);
                        }
                        onFileChange(files);
                    }
                    clearTimeout(dragLeaveTimeout.current);
                    setShowDrag(false);
                }
            }),
        ];
        return () => subscriptions.forEach(x => x.unsubscribe());
    });

    useEffect(() => {
        setFiles(params.files ?? []);
    }, [params.files]);

    const onFileChange = async (fileList: File[]) => {
        if (!fileList.length) { return; }
        const filesToUpload = [...fileList].filter(file => {
            if (file.size > 24000000) {
                ToastService.showToast(TranslationService.translate.FileSizeError, undefined, "danger");
                return false;
            }
            return true;
        });
        setLoading(true);
        const result = variant ? await FilePortalService.multiupload(filesToUpload, token) : await FileService.multiupload(filesToUpload);
        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            setLoading(false);
            return false;
        } else {
            setLoading(false);
            const filesData = variant ?
                (result as MultiuploadResultDataType)?.data?.files.map(x => ({ id: x.id, name: x.filename })) :
                (result as MultiuploadResultType)?.files?.map(x => ({ id: x.id, name: x.filename }))
            updateFiles([...files, ...filesData]);
        }
    };

    const removeFile = (id: string) => {
        updateFiles([...files.filter(x => x.id !== id)]);
        if (fileDelete) { fileDelete(id); }
    };


    return (
        <>
            {params.canEdit &&
                <>{showDrag ?
                    <div ref={dropRef} className="drop-files-box">
                        {TranslationService.translate.DropYourFilesHere}
                    </div>
                    :
                    <label className="d-flex align-items-center has-pointer removeOnPrint mb-1">
                        <input type="file" multiple style={{ display: "none" }} onChange={(e) => onFileChange([...e.target.files ?? []])} />
                        <div style={{ width: 45 }}>
                            <ButtonTooltipIcon icon="far fa-paperclip" colorIcon="text-secondary" isLink={false} title={TranslationService.translate.SelectOrDragAFile} onClick={() => { }} />
                        </div>
                        <div className="files-container col mb-1 gap-1">
                            {files.length > 0 ? <FilesAttached {...{ files, downloadFile, removeFile, loading, onFileChange }} /> : <>{TranslationService.translate.SelectOrDragAFile}</>}{loading && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                        </div>
                    </label>
                }</>
            }
        </>
    );
};
