import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import { DashboardIOStatusResponse } from "../../../entities/company/Dashboard/DashboardIOStatusResponse";
import CompanyService from "../../../services/CompanyService";
import DashboardService from "../../../services/DashboardService";
import { FilterCollection } from "../../../services/FilterService";
import { formatCurrency } from "../../../utils/FormatUtils";
import Loading from "../../shared/components/Loading";
import { TranslationService } from '../../../services/TranslationService';
import DashboardHeader from "./DashboardHeader";
import TooltipComponent from "../../shared/TooltipComponent";

const PriorityChart = ({ dashboard, dashboardFilters }: { dashboard: DashboardEntity, dashboardFilters: FilterRequest[] }) => {
    const { translate, currentLanguage } = TranslationService;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<DashboardIOStatusResponse>();
    const history = useHistory();
    const requestData = async () => {
        setLoading(true);
        const result = await DashboardService.calculate(dashboard.DashboardID, dashboardFilters);
        setLoading(false);
        if (result instanceof Error) {
            setError(true);
            return;
        }
        const dashboardResult = result as DashboardIOStatusResponse;
        setResponse(dashboardResult);
    }

    const requestDataCallback = useCallback(requestData, [dashboard.DashboardID, dashboardFilters]);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const gotoClient = (index: number) => {
        dashboardFilters.push({
            field: "iostatus",
            operator: 0,
            value: response?.totals[index].IOStatusID.toString() ?? ""
        });
        const qs = encodeURI(DashboardService.GetQueryFilter(dashboardFilters, FilterCollection.Client) || "");
        history.push(`/${currentLanguage}/client?filter=${qs}`)
    }

    if (loading) {
        return (<Loading height={270} />)
    }

    if (error || !response) {
        return <></>
    }
    return (
        <div className="card">
            <div className="card-body">
                <DashboardHeader title={translate.IOStatusTitle} />
                <div className="table-responsive">
                    <table className="table tablePriority">
                        <thead>
                            <tr>
                                <th style={{ width: "280px" }}>{translate.InvoiceStatus}</th>
                                <th style={{ width: "100px" }} className="text-end">{translate.Invoices}</th>
                                {/*<th style={{ width: "100px" }} className="text-end">{translate.AmountDue}</th>
                            <th style={{ width: "100px" }} className="text-end">{translate.NotDueAmount}</th>*/}
                                <th style={{ width: "100px" }} className="text-end">{translate.TotalAmountTitle}</th>
                                <th style={{ width: "200px" }}>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {response.totals.map((x, index) => (
                                <tr key={index} onClick={() => { gotoClient(index); }} className="pointer">
                                    <td className="fw-normal">
                                        {x.Value ?? CompanyService.getNormalIOStatusName()}
                                    </td>
                                    <td className="fw-normal text-end">{!isNaN(x.total) ? x.total : '-'}</td>
                                    {/*<td className="fw-normal text-danger text-end">
                                    {formatCurrency(x.dueAmount, CompanyService.getDefaultCurrencyId())}
                                </td>
                                <td className="fw-normal text-end">
                                    {formatCurrency(x.amount - x.dueAmount, CompanyService.getDefaultCurrencyId())}
                                </td>*/}
                                    <td className="fw-normal text-end">
                                        {!isNaN(x.amount) ? formatCurrency(x.amount, CompanyService.getDefaultCurrencyId()) : '-'}
                                    </td>

                                    <td className="bar-progress">
                                        <TooltipComponent title={<div>
                                            <div className="text-danger">{TranslationService.translate.Due} {!isNaN(x.dueAmount) ? formatCurrency(x.dueAmount) : '-'}</div>
                                            <div className="text-primary">{TranslationService.translate.NoDue} {!isNaN((x.amount - x.dueAmount)) ? formatCurrency(x.amount - x.dueAmount) : '-'}</div>
                                        </div>} >
                                            <div className="bar">
                                                <div className="expired" style={{ width: (Math.max(x.dueAmount, 0) * 100 / response.max) + "%" }}></div>
                                                <div className="not-expired" style={{ width: (Math.max(x.amount - x.dueAmount, 0) * 100 / response.max) + "%" }}></div>
                                            </div>
                                        </TooltipComponent>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default PriorityChart;