import { useEffect, useRef } from "react";
import { Collapse } from "bootstrap";

const Collapsable: React.FC<{ show: boolean }> = ({ show, children }: React.PropsWithChildren<{ show: boolean }>) => {
    const collapsableDiv = useRef(null);
    useEffect(() => {
        if (collapsableDiv.current) {
            Collapse.getOrCreateInstance(collapsableDiv.current, { toggle: show })[show ? "show" : "hide"]();
        }
    }, [show]);
    return (
        <div className="collapse" ref={collapsableDiv}>{children}</div>
    );
};
export default Collapsable;
