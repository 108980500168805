import { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ErrorContext, { ErrorProvider } from "../shared/ErrorContext";
import Logon from "./Logon";
import { Contact } from "./Contact";
import RecoverPassword from "./RecoverPassword";
import CompleteSignin from "./CompleteSignin";
import StaticHeader from "./components/StaticHeader";
import StaticFooter from "../shared/Footer";
import AuthenticationContext from "../shared/AuthenticationContext";
import ChangePassword from "./ChangePassword";
import logo from "../../assets/logo-default.png";
import "../../styles.css";

export const BodyContainer = (props) => {
    return (
        <div
            className={
                "d-flex flex-column justify-content-center align-items-center flex-grow-1 py-5"
            }
            style={{ backgroundColor: "#f2f1f6", minHeight: "calc(100vh - 179px)" }}
        >
            <img
                src={logo}
                alt="intiza"
                width={"150px"}
                height={"auto"}
                className={"d-none mb-3 mt-1"}
            />
            {props.children}
        </div>
    );
};

export const HomeErrorMessage = () => {
    const { currentError } = useContext(ErrorContext);

    return (
        <div
            id="errorMsj"
            className="incorrect"
            style={{
                color: "red",
                fontSize: "14px",
                textAlign: "center",
                margin: "0px 0px 10px",
            }}
            dangerouslySetInnerHTML={{ __html: currentError }}
        />
    );
};

const AuthSite = (props) => {
    const { isAuthenticated } = useContext(AuthenticationContext);
    return isAuthenticated ? (
        <Redirect to={`/${props.lang}/dashboard`} />
    ) : (
        <ErrorProvider>
            <div className="old-page">
                <StaticHeader lang={props.lang} />
                <BodyContainer>
                    <div className="pt-5">
                        <div className="container logon" id="signin">
                            <HomeErrorMessage />
                            <Switch>
                                <Route path="/:lang/logon" component={Logon} />
                                <Route path="/:lang/home/contact" component={Contact} />
                                <Route
                                    path="/:lang/recoverpassword"
                                    component={RecoverPassword}
                                />
                                <Route
                                    path="/:lang/home/recoverpassword"
                                    component={RecoverPassword}
                                />
                                <Route path="/:lang/recover" component={RecoverPassword} />

                                <Route
                                    path="/:lang/changepassword"
                                    component={ChangePassword}
                                />
                                <Route
                                    path="/:lang/account/changepassword"
                                    component={ChangePassword}
                                />

                                <Route
                                    path="/:lang/account/completesignin"
                                    component={CompleteSignin}
                                />
                                <Route
                                    path="/:lang/completesignin"
                                    component={CompleteSignin}
                                />
                                <Route path="/:lang/2af" component={CompleteSignin} />
                                {/* <Redirect to={`/${props.lang}/logon`} /> */}
                            </Switch>
                        </div>
                    </div>
                </BodyContainer>
                <StaticFooter lang={props.lang} />
            </div>
        </ErrorProvider>
    );
};

export default AuthSite;
