import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { TranslationService } from "../../services/TranslationService";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import TabPosition from "../shared/TabPosition";
import ClientService, { ClientFields, getClientDefaultFieldProperty, getClientFieldDisplayValue, getClientFieldIsDefault } from "../../services/ClientService";
import ToastContext, { ToastService } from "../shared/bootstrap/Toast";
import CompanyService, { DataType, Entities } from "../../services/CompanyService";
import { Client } from "./entities/ClientGetResponse";
import { formatCurrency, formatDateDigits, formatInteger, formatIntizaOldDate, formatNumber } from "../../utils/FormatUtils";
import whatsappIco from "../../assets/img/ico-whatsapp.png";
import Loading from "../shared/components/Loading";
import InvoiceList from "./invoice/InvoiceList";
import { EntityField, sortEntityFields } from "../shared/entities/EntityField";
import { ClientDetailProvider } from "./ClientDetailContext";
import { ClientDetailLeftMenuButtons } from "./components/ClientDetailLeftMenuButtons";
import { AutocompleteClient, AutocompleteGroup, DatePickerEditor } from "../shared/components/Editors";
import TableContext, { TableContextValues } from "../task/TableContext";
import Table, { TableHeader } from "../shared/Table";
import GroupService from "../../services/GroupService";
import { GroupListResponse } from "../group/entities/GroupListResponse";
import ModalService from "../shared/bootstrap/Modal";
import CashAppDetail from "./components/CashAppDetail";
import TooltipComponent from "../shared/TooltipComponent";
import ClientEdit from "./ClientEdit";
import FloatingPanelService from "../shared/FloatingPanel";
import BreadcrumbHistory from "../shared/BreadcrumbHistory";
import { ClientIterator } from "./ClientIterator";
import Dropdown from "../shared/components/Dropdown";
import { InvoiceGroups } from "./invoice/InvoiceGroups";
import { CacheEntity, StorageService } from "../../services/StorageService";
import ActivityHistory from "./activity/ActivityHistory";
import CurrencyService from "../../services/CurrencyService";
import RequestService from "../../services/RequestService";
import { InvoiceListResponse } from "./invoice/entities/InvoiceListResponse";
import { BadlySpelled2, Cast, Choose, isNullOrWhitespace } from "../../utils/Utils";
import CashappService from "../../services/CashappService";
import InvoiceGroupProvider from "./invoice/providers/InvoiceTableProvider";
import { handleErrorWithToast } from "../../utils/RequestUtils";
import { ClientDashboardExtraValues } from "./components/ClientDashboardExtraValues";
import { FixToTop } from "../shared/FixToTop";
import SearchInput from "../shared/components/SearchInput";
import DashboardClientPanel from "../company/DashboardPanel/DashboardClientPanel";
import DashboardClientSales from "./components/DashboardClientSales";
import ReactSwitch from "react-switch";

export enum ModalTypeDefault {
    Email = "1",
    Comments = "2",
    Tasks = "3",
    SMS = "4",
    WhastApp = "6",
}

export const getClientFieldDisplayValueHtml = (field: EntityField, client: Client, highlight: boolean, copyText?: (text: string) => void, currencyId: number = CompanyService.getDefaultCurrencyId()) => {
    let displayValue = getClientFieldDisplayValue(field, client);

    if (field.id === ClientFields.followupMail) {
        return <>
            {client.followUpMail}
            {copyText &&
                <TooltipComponent title={TranslationService.translate.CopyToClipboard} placement="right">
                    <i className="fal fa-copy is-link px-2" onClick={() => { copyText(displayValue); }}></i>
                </TooltipComponent>
            }
        </>;
    }

    if (!displayValue) { return undefined; }

    switch (field.type) {
        case DataType.Date:
            return formatDateDigits(new Date(displayValue));
        case DataType.Link:
            return highlight ?
                (
                    <>
                        <a target="_blank" rel="noopener noreferrer" className="overflow-hidden" href={displayValue}>
                            {displayValue}

                        </a>
                        <a target="_blank" rel="noopener noreferrer" href={displayValue}>
                            <i className="far fa-external-link ms-2"></i>
                        </a>
                    </>
                )
                :
                (
                    <a target="_blank" rel="noopener noreferrer" href={displayValue}>
                        {displayValue}
                        <i className="far fa-external-link ms-2"></i>
                    </a>
                );
        case DataType.Number:
            displayValue = formatNumber(displayValue);
            break;
        case DataType.Currency:
            return <>{formatCurrency(displayValue, currencyId)}</>;
        case DataType.Phone:
            return highlight ?
                (<>
                    <a href={"tel:" + CompanyService.getPhoneLink(displayValue)} className="d-flex overflow-hidden">
                        {displayValue}
                    </a>
                    {field.Whatsapp &&
                        <a target="_blank" className="d-flex ms-2" rel="noopener noreferrer" href={CompanyService.getWhatsappUrl(displayValue)}>
                            <img src={whatsappIco} width="20" height="20" alt="whatsapp icon" />
                        </a>
                    }
                </>
                )
                :
                (
                    <>
                        <a href={"tel:" + CompanyService.getPhoneLink(displayValue)} className="">
                            {displayValue}
                        </a>
                        {field.Whatsapp &&
                            <a target="_blank" className="d-flex ms-2" rel="noopener noreferrer" href={CompanyService.getWhatsappUrl(displayValue)}>
                                <img src={whatsappIco} width="20" height="20" alt="whatsapp icon" />
                            </a>
                        }
                    </>
                );
    }
    return highlight ? (
        <TooltipComponent title={displayValue} placement="bottom">
            {displayValue}
        </TooltipComponent>
    ) : (
        <>
            {displayValue}
        </>);
};

const ClientFieldDisplayByContent = ({ title, value, link, isMail, tooltip, copyText }: { title: string, value: string | JSX.Element, link?: string, isMail?: boolean, tooltip?: string, copyText?: (text: string) => void }) => {
    return (
        <li className="row aboutCompanyItem">
            <h5 className="col-12">{title} </h5>
            <div className="col-12 pe-0" style={{ verticalAlign: "middle" }}>
                {link && <a className="ellipsis-oneline d-inline" href={(isMail ? "mailto:" : "") + link} rel="noreferrer" target="_blank">{value}<i className="far fa-external-link ms-2"></i></a>}
                {link && copyText && <TooltipComponent title={TranslationService.translate.CopyToClipboard} placement="right"><i className="fal fa-copy is-link px-2" onClick={() => { copyText(link); }} /></TooltipComponent>}
                {!link && <label>{value}</label>}
                {/* {tooltip && <TooltipComponent title={tooltip} placement="right"><i className=" fal fa-question-circle"></i></TooltipComponent>} */}
            </div>
        </li >
    );
};

const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    ToastService.showToast(TranslationService.translate.CopiedToClipboard, undefined, "success");
};
const ClientFieldDisplay = ({ field, client, showAnyway = false, onClientSave }: { field: EntityField, client: Client, showAnyway?: boolean, onClientSave?: () => void }) => {
    const title = field.title;
    const [value, setValue] = useState(() => getValue(field, client) || "");
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const inputValue = useRef<string>(value ?? "");

    useEffect(() => {
        setValue(() => getValue(field, client));

        return () => {
            setValue(undefined);
        };
    }, [client, field]);


    if (!value && !showAnyway) {
        return (<></>);
    }
    const onSave = async () => {
        setSaving(true);
        if (getClientFieldIsDefault(field.id)) {
            const key = getClientDefaultFieldProperty(field.id);
            Cast<Record<string, string>>(client)[key] = inputValue.current;
        } else {
            const additional = client.additionals.find(x => (x.id === field.id) || (x["Id" as keyof typeof x] === field.id));
            if (additional) {
                additional.value = inputValue.current;
            } else {
                const additional = { id: field.id, value: inputValue.current };
                client.additionals.push(additional);
            }
        }

        await handleErrorWithToast(ClientService.setField(client.PersonId.toString(), field.id, inputValue.current), () => setSaving(false));

        setValue(inputValue.current);
        setSaving(false);
        setEditing(false);
        onClientSave && onClientSave();
    };
    const dropdownItems = field.type !== DataType.List ? [] : CompanyService.getAdditionalDefinitions().find(x => x.AdditionalDefinitionID === field.id)!.AdditionalDefinitionItems.map(x => ({ text: x.Value, value: x.AdditionalDefinitionItemID }));
    const dropdownItemValue = dropdownItems.find(x => x.value?.toString() === value?.toString())?.value || [];

    return (
        <li className="row aboutCompanyItem">
            <h5 className="col-12">{title}</h5>
            <div className="col-12 pe-0 show-child-on-hover">
                {!editing &&
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="ellipsis-oneline d-flex break-word">{getClientFieldDisplayValueHtml(field, client, false, copyText)}
                        </p>
                        {field.ManualEdit && <i className="fa-regular fa-pen px-2 show-when-hovering-parent pointer" onClick={() => setEditing(true)} />}
                    </div>}
                {editing &&
                    <div className="row">
                        <div className="col p-0">
                            {field.type === DataType.Date ?
                                <DatePickerEditor onChange={val => inputValue.current = formatIntizaOldDate(new Date(val))} defaultValue={value.toString()} />
                                : field.type === DataType.List ?
                                    <Dropdown items={dropdownItems} defaultValue={dropdownItemValue} onChange={(value) => inputValue.current = value.toString()} />
                                    : <input className="form-control" type={field.type === DataType.Number ? "number" : "text"} defaultValue={value?.toString()} onChange={e => inputValue.current = e.target.value} />}
                        </div>

                        <div className="col-auto p-0 d-flex align-items-center">
                            {saving ? <>
                                <i className="fa-fw fa-solid fa-check react-datepicker__day--outside-month ms-2"></i>
                                <i className="fa-fw fa-solid fa-spinner-third fa-spin third ms-1"></i>
                            </> : <>
                                <i className="fa-fw fa-solid fa-check ms-2 text-primary pointer" onClick={onSave} />
                                <i className="fa-fw fa-solid fa-times ms-1 text-danger pointer" onClick={() => { setEditing(false); inputValue.current = value; }} />
                            </>}
                        </div>
                    </div>}
            </div>
        </li>
    );
};

function getValue(field: EntityField, client: Client) {
    const displayValue = getClientFieldDisplayValue(field, client);
    if (field.type !== DataType.List) {
        return displayValue;
    }
    return CompanyService.getAdditionalDefinitions().find(x => x.AdditionalDefinitionID === field.id)!.AdditionalDefinitionItems.find(x => x.Value === displayValue)?.AdditionalDefinitionItemID.toString();
}

const ClientFieldDisplayRight = ({ field, client }: { field: EntityField, client: Client }) => {
    const title = field.title;
    const displayValue = getClientFieldDisplayValueHtml(field, client, true, copyText);
    if (!displayValue) {
        return (<></>);
    }
    return (
        <div className="creditLimitBox">
            <h5>{title}</h5>
            <p style={{ maxHeight: "1lh" }} className='overflow-hidden link-to' >{displayValue}</p>
        </div>
    );
};
type ExtraValues = Exclude<Awaited<ReturnType<typeof ClientService.getExtraValues>>, Error>;
export const ClientDetail = () => {
    const { translate, currentLanguage } = TranslationService;
    const [client, setClient] = useState<Client | null | undefined>();
    const [agrupadoId, setAgrupadoId] = useState<number>();
    const [loadingAgrupado, setLoadingAgrupado] = useState<boolean>(false);
    const [agrupado, setAgrupado] = useState<Client | null | undefined>();
    const [customerToken, setCustomerToken] = useState<string>();
    const groupRef = useRef<{ setValue: (state: { value: number, label: string }) => void }>();
    //const [pendingReceivables, setPendingReceivables] = useState(0);
    const [, setSegments] = useState<string>();
    const [extraValues, setExtraValues] = useState<ExtraValues>();

    const [groupId, setGroupId] = useState<number>();
    const needClientCurrency = CompanyService.getSetting(BadlySpelled2("showtotalincustomercurrency", "showtotalintcustomercurrency"));
    const [displayCurrencyId, setDisplayCurrencyId] = useState<number>(needClientCurrency ? 0 : CompanyService.getDefaultCurrencyId());
    const [cashappClientId, setCashappClientId] = useState<string | null>(null);


    useEffect(() => {
        if (needClientCurrency && !displayCurrencyId) {
            RequestService.intercept("/io/list", (result: InvoiceListResponse) => setDisplayCurrencyId(result.currencyId));
        }
    }, [displayCurrencyId, needClientCurrency]);

    useEffect(() => {
        if (!client || isNullOrWhitespace(CompanyService.getCompanyToken()?.cashappid)) {
            setCashappClientId(null);
            return;
        }
        const getData = async () => {
            const personKey = CompanyService.getCompanyAuth()!.PersonKey!;
            const clientCode = Choose(
                () => client.additionals.find(x => x.id === personKey)?.value,
                () => ClientService.getClientHeaders().find(x => x.key === personKey.toString())?.fieldId,
                () => personKey === ClientFields.Name ? client.Name : undefined,
                () => personKey === ClientFields.Address ? client.Address : undefined,
                () => personKey === ClientFields.Phone ? client.Phone : undefined,
            );
            if (clientCode === undefined) {
                setCashappClientId(null);
            } else {
                const result = await CashappService.getClientId(clientCode);
                setCashappClientId(result instanceof Error ? null : result.clientId);
            }
        };
        getData();
    }, [client]);

    const { state, search } = useLocation<{ clientIterator: ClientIterator, prevRequest: unknown }>();
    const clientId = parseInt(new URLSearchParams(search).get("id") ?? "");
    const openModalDefault = (new URLSearchParams(search).get("openModalDefault") ?? "");

    const history = useHistory();
    const [showMenu, setShowMenu] = useState(StorageService.getPreferences().clientDetailsCollapsed);



    const rightColumnFields = [
        ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client, true).map(x => new EntityField(x)),
    ].sort(sortEntityFields(CompanyService.getPersonSortedFields().split(",")));
    const invoiceGroups = CompanyService.getIOGroup();


    const toggleMenu = () => {
        setShowMenu(x => {
            StorageService.setPreference("clientDetailsCollapsed", !x);
            return !x;
        });
    };

    const collGet = (x: null | HTMLDivElement) => {
        if (x === null) {
            return;
        }
        if (StorageService.getPreferences().clientDetailsCollapsed) {
            x.classList.add("show");
        }
    };

    const loadClient = useCallback(() => {
        const loadClient = async () => {
            if (agrupadoId !== undefined) {
                loadAgrupado();
            }
            const result = await ClientService.get(clientId);
            if (result instanceof Error) {
                // showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setClient(null);
                !agrupadoId && setAgrupado(null);
                return;
            }
            // setPendingReceivables(result.pendingReceivables);
            !agrupadoId && setSegments(result.segments?.map(x => x.Name).join(", "));
            setClient(result.item);
            !agrupadoId && setAgrupado(result.item);
            setCustomerToken(result?.customertoken);
        };
        const loadAgrupado = async () => {
            setLoadingAgrupado(true);
            const result = await ClientService.get(agrupadoId!);
            if (result instanceof Error) {
                // showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setAgrupado(null);
                setLoadingAgrupado(false);
                return;
            }
            //setPendingReceivables(result.pendingReceivables);
            setSegments(result.segments?.map(x => x.Name).join(", "));
            setAgrupado(result.item);
            setCustomerToken(result?.customertoken);
            setLoadingAgrupado(false);
        };
        const loadExtraValues = async () => {
            const result = await ClientService.getExtraValues(clientId);
            setExtraValues(result instanceof Error ? undefined : result);
        };

        loadExtraValues();
        loadClient();
    }, [agrupadoId, clientId]);

    useEffect(() => {
        loadClient();
    }, [loadClient]);

    const convertClientCurrency = (amount: number | undefined) => displayCurrencyId ? CurrencyService.convert(amount ?? 0, CompanyService.getDefaultCurrencyId(), displayCurrencyId) : 0;

    const reloads = { invoice: () => undefined, activity: () => undefined, client: loadClient, groups: () => undefined };
    const reload = () => {
        reloads.client();
        reloads.activity();
        reloads.groups();
        reloads.invoice();
    };

    if (client === null || agrupado === null) {
        ModalService.showDefaultModal({
            message: translate.ClientNotFound.replace("{company}", CompanyService.getCompanyName()!),
        });
        return (<Redirect to={"/" + currentLanguage + "/client"} />);
    }

    const setGroupFilter = (name: string, groupId: number) => {
        groupRef.current!.setValue({ value: groupId, label: name });
    };

    const openEditClientModal = () => {
        FloatingPanelService.showPanel({
            title: translate.EditClient,
            children: <ClientEdit client={client} onClientSave={() => reload()} onClientDeleted={() => { history.push(`/${currentLanguage}/client`); }} />,
            width: 600,
            height: 700,
            position: "bottom-right"
        });
    };

    const showCashapp = [CompanyService.getCompanyToken()?.cashappid, cashappClientId]
        .reduce<boolean>((prev, curr) => prev && !isNullOrWhitespace(curr), true);




    return (
        <ClientDetailProvider reloadExtraData={() => { loadClient(); reloads.activity(); }}>
            <TabPosition module="client">
                <section className="debts" key={clientId}>
                    <div>
                        {client === undefined || agrupado === undefined ?
                            <Loading /> :
                            <div className="row flex-nowrap g-0 app-height">
                                <div className="col-auto p-0 debtsMenu h-100">
                                    <div className="collapse collapse-horizontal h-100" id="collapseMenu" ref={collGet}>
                                        <div className="d-flex flex-column h-100">
                                            <div className="resumeCompany">
                                                {client?.GroupedPerson && client.GroupedPerson.length > 0 &&
                                                    <div className="viewData row" style={{ alignItems: "center" }}>
                                                        <div className="col-5">
                                                            <label style={{ fontSize: "13px" }}>{<>{translate.ViewDataFrom}:{loadingAgrupado && <i className="fas fa-spinner-third fa-spin third ms-2" />}</>}
                                                            </label>
                                                        </div>
                                                        <div className="col">
                                                            <Dropdown secondaryVariant items={client.GroupedPerson.map(x => ({ text: x.Name, value: x.PersonId }))} onChange={setAgrupadoId} optionLabel={translate.MainCompany} selectClasses="col" />
                                                        </div>
                                                    </div>
                                                }
                                                <ClientDetailLeftMenuButtons client={client} clientEmail={client?.Email} reload={reload} toggleMenu={() => toggleMenu()} direction={"column"} />
                                                <ClientDetailLeftMenuButtons client={client} clientEmail={client?.Email} reload={reload} toggleMenu={() => toggleMenu()} direction="row" />
                                            </div>
                                            <AboutCompany extraValues={extraValues} agrupado={agrupado} showMenu={!!showMenu} openEditClientModal={openEditClientModal} customerToken={customerToken} onClientSave={reload} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col debtsDetail h-100 overflow-auto">
                                    <div className="ps-3 mb-0 pt-4 position-relative z-index-10">
                                        <BreadcrumbHistory
                                            defaultPrevious={{
                                                link: () => {
                                                    history.push(`/${TranslationService.currentLanguage}/client`, { prevRequest: state.prevRequest });
                                                }, name: (state?.clientIterator?.items?.length > 0) ? TranslationService.translate.Clients + ` (${TranslationService.translate.Filtered})` : translate.Clients
                                            }}
                                            current={{ name: client.Name, link: `client/details?id=${client.PersonId}` }}
                                        >
                                            <span className="breadcrumb-controls ms-auto d-flex align-items-center flex-row mb-0">
                                                {state?.clientIterator?.hasPrev && <>
                                                    {state.clientIterator.hasPrev() && <button className="btn btn-link" onClick={() => { setClient(undefined); state.clientIterator.goPrev(history); }}>{TranslationService.translate.Previous}</button>}
                                                    {state.clientIterator.hasPrev() && state.clientIterator.hasNext() && <>|</>}
                                                    {state.clientIterator.hasNext() && <button className="btn btn-link" onClick={async () => { await state.clientIterator.goNext(history); setClient(undefined); }}>{TranslationService.translate.Next}</button>}
                                                </>}
                                                {TranslationService.translate.GoTo}
                                                <span className="ms-2 mb-1" style={{ width: 200 }}>
                                                    <AutocompleteClient onChange={(x) => {
                                                        if (x) {
                                                            setClient(undefined); history.push(`/${currentLanguage}/client/details?id=${x}`);
                                                        }
                                                    }} />
                                                </span>
                                            </span>
                                        </BreadcrumbHistory>
                                    </div>
                                    <FixToTop isFixed backgroundClass="">
                                        <div className="ps-3 sticky-clientname d-flex justify-content-between">
                                            {(CompanyService.canDo("editperson") && !CompanyService.getSetting("hideeditperson")) ?
                                                <div className={"nameCompany mb-3 canEdit"}>
                                                    <h2 onClick={openEditClientModal}>
                                                        {agrupado.Name}<i className="far fa-pen"></i>
                                                    </h2>
                                                </div>
                                                :
                                                <div className={"nameCompany mb-3"}>
                                                    <h2>{agrupado.Name}</h2>
                                                </div>
                                            }
                                            <ScrollNav />
                                        </div>
                                    </FixToTop>
                                    {showCashapp && <ul className="nav nav-pills client-detail" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-collections-tab" data-bs-toggle="pill" data-bs-target="#pills-collections" type="button" role="tab" aria-controls="pills-collections" aria-selected="true">
                                                {translate.Collections}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-reconciliations-tab" data-bs-toggle="pill" data-bs-target="#pills-reconciliations" type="button" role="tab" aria-controls="pills-reconciliations" aria-selected="false">
                                                {translate.Reconciliations}
                                            </button>
                                        </li>
                                    </ul>}
                                    <div className="tab-content p-0" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-collections" role="tabpanel" aria-labelledby="pills-collections-tab">
                                            <div className="mt-3">
                                                {(displayCurrencyId > 0) && extraValues ?
                                                    <DashboardClientSales
                                                        averagePeriodPerson={formatInteger(extraValues.averagePeriodPerson)}
                                                        averagePeriod={formatInteger(extraValues.averagePeriod)}
                                                        averageAgingPerson={formatInteger(extraValues.averageAgingPerson)}
                                                        averageAging={formatInteger(extraValues.averageAging)}
                                                        // {showAging && <MenuBox title={TranslationService.translate.DebtAging}  content={formatInteger(extraValues.averageAgingPerson) + " " + TranslationService.translate.Days + " (" + formatInteger(extraValues.averageAging) + ")"}></MenuBox>}
                                                        totalAmount={convertClientCurrency(client.amount)}
                                                        currencyId={displayCurrencyId} />
                                                    : <Loading height={90} />}
                                            </div>
                                            <DashboardClientPanel clientId={clientId} />
                                            {(client.CreditLimit ?? 0) > 0 &&
                                                <div className="card mt-3 p-0">
                                                    <ClientDashboardExtraValues {...{ client, extraValues, displayCurrencyId, convertClientCurrency }} />
                                                </div>}
                                            {rightColumnFields &&
                                                <div className="row creditLimit">
                                                    <div className="creditLimitGrid">
                                                        {rightColumnFields.map(field =>
                                                            <ClientFieldDisplayRight key={field.id} field={field} client={agrupado} />
                                                        )}
                                                    </div>
                                                </div>}
                                            <InvoiceGroupProvider personid={client.PersonId}>
                                                {invoiceGroups?.length > 0 &&
                                                    <div className="card mt-3 p-0">
                                                        <section className="clientsTabs">
                                                            <div className="tab-content">
                                                                <div className="tab-pane fade show active">
                                                                    <InvoiceGroups groups={invoiceGroups} />
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>}
                                                {<>
                                                    <div className="card mt-3 p-0">
                                                        <section className="clientsTabs">
                                                            {CompanyService.getSetting("showgrouptable") &&
                                                                <GroupTable personId={clientId} setReloads={reloads} setGroupFilter={setGroupFilter} actions={<div className="col-3 group-filter"><AutocompleteGroup clientId={clientId.toString()} onChange={x => setGroupId(x && parseInt(x.value))} ref={groupRef} /></div>} />}
                                                            {showCashapp && <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                                {/* <li className="nav-item" role="presentation">
                                                                    <button className="nav-link active" data-bs-toggle="pill" data-bs-target="#invoices-tab-pane" type="button" role="tab" ><p><i className="far fa-file me-2"></i> {translate.Invoices}</p><span className="rounded"></span></button>
                                                                </li> */}
                                                                <li className="nav-item" role="presentation">
                                                                    <button className="nav-link" data-bs-toggle="pill" data-bs-target="#payments-tab-pane" type="button" role="tab" ><p><i className="far fa-cash-register me-2"></i> {translate.Payments}</p><span className="rounded"></span></button>
                                                                </li>
                                                            </ul>}
                                                            <div className="tab-content p-0">
                                                                <div className="tab-pane fade show active" id="invoices-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                                                                    <InvoiceList client={client} groupId={groupId} setReloads={reloads} reload={reload} />
                                                                </div>
                                                                {showCashapp && <div className="tab-pane fade" id="payments-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                                                                    <CashAppDetail iframeStyle={{ width: "100%", height: "300px" }} url={CashappService.getBaseUrl() + "/Payment/GetClientPendingPaymentList?clientId=" + cashappClientId} />
                                                                </div>}
                                                            </div>
                                                        </section>
                                                    </div>
                                                    <div className="mt-3 p-0">
                                                        <section className="clientsTabs">
                                                            {/*COMENTADO HASTA TENER MAS DE UNA OPCION
                                                            https://intizadev.atlassian.net/browse/GDC-447

                                                            <ul className="nav nav-pills" id="activity-tab" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active" data-bs-toggle="pill" data-bs-target="#activity-tab-pane" type="button" role="tab"><p><i className="far fa-history me-2"></i> {translate.ActivityHistory}</p><span className="rounded"></span></button>
                                                            </li>
                                                            </ul>*/}
                                                            <div className="tab-content">
                                                                <div className="tab-pane fade show active" id="activity-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                                                                    <ActivityHistory
                                                                        personid={clientId.toString()}
                                                                        person={client}
                                                                        emailAddress={client.Email}
                                                                        groupId={groupId}
                                                                        setReloads={reloads}
                                                                        openModalDefault={openModalDefault} />
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </>}
                                            </InvoiceGroupProvider>
                                        </div>
                                        <div className="tab-pane fade" id="pills-reconciliations" role="tabpanel" aria-labelledby="pills-reconciliations-tab">
                                            {showCashapp && <CashAppDetail url={CashappService.getBaseUrl() + "/Client/Details/" + cashappClientId + "?hideName=true"} iframeStyle={{ width: "100%", height: "65vh" }} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </TabPosition>
        </ClientDetailProvider>
    );
};

const getNavElement = [
    () => document.querySelector("section.debts .debtsDetail")?.firstChild as HTMLDivElement,
    () => document.getElementById("invoices-tab-pane") as HTMLDivElement,
    () => document.getElementById("activity-tab-pane") as HTMLDivElement,
];
const ScrollNav = () => {
    const [activeNav, setActiveNav] = useState(0);
    const scrollingRef = useRef(false);
    const goToNav = (index: number) => {
        const element = getNavElement[index]();
        const parent = getNavElement[0]().parentElement! as HTMLFormElement;
        const top = (index === 0) ? 0 :
            parent.scrollTop + element.getBoundingClientRect().top - 150;
        if (parent.scrollTop === top) {
            return;
        }
        scrollingRef.current = true;
        parent.scrollTo({
            behavior: "smooth",
            top,
        });
        setActiveNav(index);
        parent.onscrollend = () => {
            scrollingRef.current = false;
            parent.onscrollend = null;
        };
    };
    useLayoutEffect(() => {
        const interval = setInterval(() => {
            const div = getNavElement[0]();
            const invoice = getNavElement[1]();
            const activity = getNavElement[2]();
            if (!div || !invoice || !activity) {
                return;
            }
            if (scrollingRef.current) {
                return;
            }
            if (activity.getBoundingClientRect().top < 200) {
                setActiveNav(2);
            } else if (invoice.getBoundingClientRect().top < 200) {
                setActiveNav(1);
            } else {
                setActiveNav(0);
            }
        }, 100);
        return () => clearInterval(interval);
    }, []);
    return (
        <div className="d-flex gap-2">
            {[
                TranslationService.translate.Dashboard,
                TranslationService.translate.Invoices,
                TranslationService.translate.ActivityHistory
            ].map((x, i) => <h6 key={x}><span className={"pointer badge rounded-pill " + ((activeNav === i) ? "bg-primary" : "bg-secondary")} onClick={() => goToNav(i)}>{x}</span></h6>)}
        </div>
    );
};

const GroupTable = ({ personId, actions, setGroupFilter, setReloads }: { personId: number, actions?: JSX.Element, setGroupFilter: (groupName: string, groupId: number) => void, setReloads?: { groups: () => void } }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [group, setGroup] = useState<GroupListResponse.Item[]>([]);
    const [loading, setLoading] = useState(true);

    const requestData = async () => {
        setLoading(true);
        const result = await GroupService.list(personId);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setLoading(false);
            return;
        }
        result.list.map(x => StorageService.setCache(CacheEntity.GroupName, x.GroupID, x.Name));
        setGroup(result.list);
        setLoading(false);
    };

    const requestDataCallback = useCallback(requestData, [personId, showToast, translate.ErrorProcessingRequest]);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const headers = [
        new TableHeader("group", CompanyService.getGroupName(), false, false),
        new TableHeader("io", translate.Invoices, true, false),
        new TableHeader("amount", translate.Amount, true, false),
        new TableHeader("amountDue", translate.Due, true, false),
        new TableHeader("amountNotDue", translate.NotDue, true, false),
        new TableHeader("due", translate.DueNoDue, false, false),
    ];

    const tableValues = new TableContextValues();
    tableValues.loading = loading;
    tableValues.response = { list: group };
    tableValues.error = false;
    tableValues.reload = requestData;
    const max = group.map(x => x.amount).reduce((prev, curr) => prev > curr ? prev : curr, 1);

    return (
        <TableContext.Provider value={tableValues}>
            {loading && 'asdfasdfsad'}
            <TableContext.Consumer>
                {({ reload }) => {
                    if (setReloads) {
                        setReloads.groups = reload;
                    } return (<></>);
                }}
            </TableContext.Consumer>
            <div className="table-no-border group-table pb-4">
                {/* <Table title={CompanyService.getGroupName()} headers={headers} actions={actions} item={({ data }: { data: GroupListResponse.Item }) => ( */}
                <Table headers={headers} item={({ data }: { data: GroupListResponse.Item }) => {
                    return (
                        <tr onClick={() => setGroupFilter(data.Name, data.GroupID)} className="pointer">
                            <td>{data.Name}</td>
                            <td width={80} className={"text-end"}>{data.count}</td>
                            <td width={100} className={"text-end w-sm"}>{formatCurrency(data.amount, CompanyService.getDefaultCurrencyId())}</td>
                            <td width={100} className={"text-end w-sm text-danger"}>{formatCurrency(data.overdueAmount, CompanyService.getDefaultCurrencyId())}</td>
                            <td width={100} className={"text-end w-sm"}>{formatCurrency(data.amount - data.overdueAmount, CompanyService.getDefaultCurrencyId())}</td>
                            <td className="bar-progress">
                                <div className="bar">
                                    <div className="expired" style={{ width: ((data.overdueAmount ?? 0) * 100 / max) + "%" }}></div>
                                    <div className="not-expired" style={{ width: (((data.amount - data.overdueAmount) || 0) * 100 / max) + "%" }}></div>
                                </div>
                            </td>
                        </tr>);
                }}
                ></Table>
                {actions && <div className="mt-2">{actions}</div>}
            </div>
        </TableContext.Provider>
    );
};

const AboutCompany = ({ extraValues, agrupado, showMenu, openEditClientModal, customerToken, onClientSave }: { extraValues: ExtraValues | undefined, agrupado: Client, showMenu: boolean, openEditClientModal?: () => void, customerToken?: string, onClientSave?: () => void }) => {
    const [search, setSearch] = useState("");
    const [showAll, setShowAll] = useState(false);
    const { currentLanguage } = TranslationService;
    const leftColumnFields = useMemo(() => [
        ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client, false).map(x => new EntityField(x)),
        ...CompanyService.getConfigItemsFiltered(Entities.Client, true, [ClientFields.Name, ClientFields.Contact, ClientFields.Email, ClientFields.Phone, ClientFields.Address, ClientFields.Comments]).map(x => new EntityField(x)),
    ]
        .filter(x => (x.title ?? "").toLocaleLowerCase().includes(search))
        .sort(sortEntityFields(CompanyService.getPersonSortedFields().split(","))), [search]);

    //TODO - QUITAR UNA VEZ QUE ESTE LISTO PARA PROD. DEJAR SOLO NEW
    // const newPortalDomains = ["dev2.intiza.com", "stagingv2.intiza.com"];
    // const isNewPortal = newPortalDomains.some(domain => window.location.origin.includes(domain));
    const portalLink = `${window.location.origin}/${currentLanguage}/portalclient?t=` + customerToken;

    return (
        <>
            {showMenu && <div className="searcherFilterHeader m-3">
                <SearchInput onSearch={val => setSearch(val.toLocaleLowerCase() ?? "")} />
            </div>}
            <div className="aboutCompany">
                <ul>
                    {leftColumnFields.map(field =>
                        <ClientFieldDisplay key={field.id} field={field} client={agrupado} showAnyway={showAll} onClientSave={onClientSave} />
                    )}
                    {agrupado.followUpMail && TranslationService.translate.FollowUpMail.toLocaleLowerCase().includes(search) &&
                        <ClientFieldDisplayByContent title={TranslationService.translate.FollowUpMail} value={agrupado.followUpMail} tooltip={TranslationService.translate.FollowUpMailTip} link={agrupado.followUpMail} isMail={true} copyText={copyText}></ClientFieldDisplayByContent>}
                    {CompanyService.getSetting("customerportalenabled") && agrupado.customerSite && TranslationService.translate.CustomerSite.toLocaleLowerCase().includes(search) &&
                        <ClientFieldDisplayByContent title={TranslationService.translate.CustomerSite} value={TranslationService.translate.Open} link={portalLink} copyText={copyText}></ClientFieldDisplayByContent>}
                </ul>
            </div>
            {showMenu && <div className="d-flex align-items-center justify-content-between m-3">
                <button type="button" className="btn btn-secondary py-1 " onClick={openEditClientModal}>{TranslationService.translate.EditClient}</button>
                <TooltipComponent title={TranslationService.translate.ShowAll}>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        checked={showAll}
                        onChange={() => setShowAll(!showAll)}
                        height={24}
                        width={50}
                    />
                </TooltipComponent>
            </div>}
        </>);
};

export default ClientDetail;
