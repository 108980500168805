import { ReportExportListResponse } from "../entities/reports/ReportExport/ReportExportListResponse";
import { ReportExportSetRequest } from "../entities/reports/ReportExport/ReportExportSetRequest";
import RequestService from "./RequestService";

class ReportExportService {
    public static getList(): Promise<ReportExportListResponse | Error> {
        return RequestService.post("/reportexport/list", {});
    }
    public static set(item: ReportExportSetRequest.Item): Promise<{ id: number } | Error> {
        return RequestService.post("/reportexport/set", { item });
    }
    public static delete(id: number): Promise<unknown | Error> {
        return RequestService.post("/reportexport/delete", { id });
    }
}

export default ReportExportService;