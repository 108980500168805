import { useContext, useEffect } from "react";
import { TranslationService } from "../../services/TranslationService";
import TableContext, { TableContextValues } from "../task/TableContext";
import Dropdown from "./components/Dropdown";
import { StorageService } from "../../services/StorageService";
import { useLocation } from "react-router-dom";

const Paginator = (props: Partial<TableContextValues>) => {
    const { pageCount, currentPage, setCurrentPage, setPageSize, response, pageSize, loading, error } = { ...useContext(TableContext), ...props };
    const location = useLocation();
    //const [changingPage, setChangingPage] = useState(true);
    const { translate } = TranslationService;

    const showPaginator = setCurrentPage !== undefined
        && response !== undefined
        && currentPage !== undefined
        && pageCount !== undefined
        && !error;
    const pageSizes = [10, 25, 50, 100, 200];

    useEffect(() => {
        if (!setPageSize) {
            return;
        }
        const prefPageSize = (StorageService.getPreferences().pageSizePreference ?? {})[location.pathname.substring(TranslationService.currentLanguage.length + 1)];
        if (prefPageSize && pageSize !== prefPageSize) {
            setPageSize(prefPageSize);
        } else if (pageSize && !pageSizes.includes(pageSize)) {
            const newPageSize = pageSizes.map(x => ({ val: x, diff: Math.abs(pageSize - x) }))
                .sort((a, b) => a.diff - b.diff)[0].val;
            setPageSize(newPageSize);
        }
    });

    if (!showPaginator) {
        return <></>;
    }

    const pagesToShow = 7;
    const totalPages = pageCount;

    let firstShownPage = 0;
    if (Math.round(currentPage - Math.floor(pagesToShow / 2)) < 1) {
        firstShownPage = 1;
    }
    else {
        firstShownPage = (currentPage - Math.floor(pagesToShow / 2)) + 1;
    }
    if (firstShownPage + pagesToShow > totalPages) {
        firstShownPage = totalPages - pagesToShow + 1;
    }

    if (firstShownPage < 1) {
        firstShownPage = 1;
    }
    let lastShownPage = totalPages < pagesToShow ? totalPages : firstShownPage + pagesToShow - 1;
    firstShownPage--;
    lastShownPage--;
    const shownPages = lastShownPage - firstShownPage + 1 /*currentPage*/;
    const firstItem = ((response.pageSize || response.data.pagesize) * currentPage) + 1;
    const itemCount = response.itemCount ?? response.data.count ?? response.clientCount;
    const lastItem = (firstItem + (response.pageSize || response.data.pagesize)) > (itemCount ?? 0) ? itemCount : firstItem + (response.pageSize || response.data.pagesize) - 1;

    const onPageSizeChange = (newPageSize: number) => {
        const prefs = (StorageService.getPreferences().pageSizePreference ?? {});
        prefs[location.pathname.substring(TranslationService.currentLanguage.length + 1)] = newPageSize;
        StorageService.setPreference("pageSizePreference", prefs);
        setPageSize!(newPageSize);
        setCurrentPage(0);
    };

    const changePage = (newPage: number) => {
        if (loading) {
            return;
        }
        setCurrentPage(newPage);
    };

    return (
        <nav className="navigation">
            <ul className="pagination col-md-4 col-12">
                {pageCount > 7 && <li className={"page-item" + (currentPage === 0 ? " disabled" : "")} onClick={() => currentPage === 0 ? {} : changePage(0)}>
                    <span className="page-link"><i className="fas fa-chevrons-left"></i></span>
                </li>}
                <li className={"page-item" + (currentPage === 0 ? " disabled" : "")} onClick={() => currentPage === 0 ? {} : changePage(currentPage - 1)}>
                    <span className="page-link"><i className="fas fa-chevron-left"></i></span>
                </li>
                {Array.from({ length: shownPages }).map((x, i) =>
                    <li key={i} className={"page-item" + (currentPage === (i + firstShownPage) ? " active" : "")} onClick={() => changePage(i + firstShownPage)}>
                        <span className="page-link" >{i + firstShownPage + 1}</span>
                    </li>)}
                <li className={"page-item" + (currentPage === (pageCount - 1) ? " disabled" : "")} onClick={() => currentPage === (pageCount - 1) ? {} : changePage(currentPage + 1)}>
                    <span className="page-link"><i className="fas fa-chevron-right"></i></span>
                </li>
                {pageCount > 7 && <li className={"page-item" + (currentPage === (pageCount - 1) ? " disabled" : "")} onClick={() => currentPage === (pageCount - 1) ? {} : changePage(pageCount - 1)}>
                    <span className="page-link"><i className="fas fa-chevrons-right"></i></span>
                </li>}
            </ul>
            <div className="results col-md-4 col-12">
                {(lastItem ?? 0) > 0 && <p>
                    {translate.Showing} {firstItem} - {lastItem} {translate.Of} {itemCount}
                </p>}
                {setPageSize !== undefined && <>
                    <p className="mx-2">|</p> <p className="me-2">{TranslationService.translate.ShowPerPage}</p>
                    <Dropdown items={pageSizes.map(x => ({ text: x.toString(), value: x }))} onChange={onPageSizeChange} value={response.pageSize ?? pageSize} />
                </>}
            </div>
        </nav>
    );
};

export default Paginator;