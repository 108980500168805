import { useState } from "react";
import { DataType } from "../../services/CompanyService";
import { TranslationService } from "../../services/TranslationService";
import { TableHeader, TableHeaderWithFieldId } from "../shared/Table";
import TableContext from "../task/TableContext";
import InboxListContext from "./InboxListContext";
import { QUICK_TABS } from "./InboxTabContent";
import { InboxTableTableColumn } from "./InboxTableTableColumn";

export function getInboxTableHeaders(quickfilter: number) {

    const whatsappFields: TableHeaderWithFieldId[] = [
        {
            ...new TableHeader("check", '', false, false, "w-30px"),
            fieldId: "check",
            type: DataType.YesNo,
        },
        {
            ...new TableHeader("personName", TranslationService.translate.Client, false, false, "w-25"),
            fieldId: "personName",
            type: DataType.Text,
        },
        {
            ...new TableHeader("content", TranslationService.translate.Content, false, false, "w-100"),
            fieldId: "Content",
            type: DataType.Text,
        },
        {
            ...new TableHeader("date", TranslationService.translate.Date, false, false, "w-80px"),
            fieldId: "date",
            type: DataType.Date,
        },
    ];


    const emailFields: TableHeaderWithFieldId[] = [
        {
            ...new TableHeader("check", '', false, false, "w-30px"),
            fieldId: "check",
            type: DataType.YesNo,
        },
        {
            ...new TableHeader("fromName", TranslationService.translate.Sender, false, false, "w-210px"),
            fieldId: "FromName",
            type: DataType.Text,
        },
        {
            ...new TableHeader("personName", TranslationService.translate.Client, false, false, "w-140px"),
            fieldId: "personName",
            type: DataType.Text,
        },
        {
            ...new TableHeader("subject", TranslationService.translate.Subject, false, false),
            fieldId: "Subject",
            type: DataType.Text,
        },
        {
            ...new TableHeader("date", TranslationService.translate.Date, false, false, "w-110px"),
            fieldId: "date",
            type: DataType.Date,
        },
        {
            ...new TableHeader("actions", '', false, false, "w-110px"),
            fieldId: "actions",
            type: DataType.Text,
        },
    ];

    return quickfilter === QUICK_TABS.WHATSAPP ? whatsappFields : emailFields;

}

export const InboxTableItem = (
    onCheckboxChange?: (messageId: number, checked: boolean) => void,
    headers?: TableHeaderWithFieldId[],
) => function InvoiceTableItemImpl({ data }: { data: any }) {
    const [loadingData, setLoadingData] = useState(false);

    return (
        <InboxListContext.Consumer>
            {({ messageIds, messageAll }) =>
                <TableContext.Consumer>
                    {() =>
                        <tr key={data.ID} style={{ cursor: "pointer" }}>
                            {onCheckboxChange &&
                                <td
                                    className="tdInbox"
                                    onClick={event => event.stopPropagation()} >
                                    <input
                                        type="checkbox"
                                        className="checkbox-inbox"
                                        data-invoiceselected={data.ID}
                                        defaultChecked={messageAll || messageIds.includes(data.ID)}
                                        onChange={event => onCheckboxChange(data.ID, (event.target as HTMLInputElement).checked)} />
                                </td>}
                            {headers?.filter(x => x.fieldId !== "checkbox")
                                .map(header =>
                                    <InboxTableTableColumn
                                        key={header.fieldId}
                                        data={data}
                                        header={header}
                                        loadingData={loadingData}
                                        setLoadingData={setLoadingData}
                                    />)}
                        </tr>}
                </TableContext.Consumer>
            }
        </InboxListContext.Consumer >
    );
};