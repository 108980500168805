import { useContext, useState } from 'react';
import { FloatingPanelFooter } from '../../shared/components/FloatingPanelFooter';
import { TranslationService } from '../../../services/TranslationService';
import { AutocompleteGroup, DatePickerEditor, TextAreaBigEditor, TextEditor, TextEditorNumber } from '../../shared/components/Editors';
import { DataType } from '../../../services/CompanyService';
import Dropdown from '../../shared/components/Dropdown';
import { ifOldFormat } from '../../client/invoice/InvoiceMassiveChangesFieldEdit';
import { RequiredManager, ValidationMessage } from '../../shared/RequieredManager';
import PortalClientService, { FieldDataStatus, PortalRequestSaveMessage } from '../../../services/PortalClientService';
import ToastContext from '../../shared/bootstrap/Toast';
import FloatingPanelService from '../../shared/FloatingPanel';
import { formatIntizaDate } from '../../../utils/FormatUtils';
import FilePortalService from '../../../services/FilePortalService';
import { MultiFileUpload } from '../../shared/components/MultiFileUpload';

type SendMessageComposeType = {
  ignoreFields: boolean,
  tokenId: string,
  selectedRows: number[],
  dataFields?: FieldDataStatus | null,
  selectedInvoices: { id: number, nro: string }[]
}

type FieldItemDependency = {
  AdditionalDefinitionID: number,
  AdditionalDefinitionItemID: number,
  AdditionalDefinitionItemID_ref: string | null,
  Default: string | null,
  ImportID: string | null,
  Value: string | null,
}

type FieldTypeFormated = {
  AdditionalDefinitionID: number,
  IOStatusDependencyID: number,
  IOStatusID: number,
  Id: number,
  ListItems: FieldItemDependency[],
  Mandatory: boolean,
  Title: string,
  Type: number,
  Value: string,
}

export const SendMessageCompose = ({ dataFields, ignoreFields, tokenId, selectedRows, selectedInvoices }: SendMessageComposeType) => {
  const { showToast } = useContext(ToastContext);
  const [messageData, setMessageData] = useState<PortalRequestSaveMessage>({
    ...new PortalRequestSaveMessage(),
    ids: selectedRows.join(),
    iostatusid: dataFields?.IOStatusID || 0
  })

  const requiredManager = new RequiredManager()
  const dataFieldsFormated: FieldTypeFormated[] = dataFields?.Dependencies?.map((x) => ({ ...x, Id: x.AdditionalDefinitionID, Value: '' })) || [];
  const [fieldData, setFieldData] = useState<FieldTypeFormated[]>(dataFieldsFormated);
  const [isSaving, setIsSaving] = useState(false);

  const show = !ignoreFields && dataFieldsFormated.length > 0;
  const sendMessage = async () => {
    if (!messageData || !requiredManager.validate()) {
      return;
    }
    setIsSaving(true);
    // const invoices = selectedInvoices.length > 0 ?
    //   ` - ${TranslationService.translate.Invoices} ` + selectedInvoices.map(x => x.nro).join(', ') :
    //   '';
    const messageWithInvoices = (messageData.comment || '');
    const result = await PortalClientService.saveMessage({
      ...messageData,
      comment: messageWithInvoices,
      fieldsandvalues: fieldData.map(x => ({ id: x.Id, value: x.Value }))
    }, tokenId)
    if (result instanceof Error) {
      showToast(TranslationService.translate.ErrorProcessingRequest, '', 'danger');
      setIsSaving(false);
      return
    }
    showToast(TranslationService.translate.Mail_Sent, '', 'success');
    setIsSaving(false);
    FloatingPanelService.hidePanel();
  }

  const messageFieldChange = (propName: keyof PortalRequestSaveMessage) => (newValue: string | number | boolean | any | undefined) => {
    setMessageData(x => ({ ...x, [propName]: newValue }));
  };
  const mailChange = requiredManager.makeRequired(messageFieldChange("email"));
  const commentChange = requiredManager.makeRequired(messageFieldChange("comment"));

  const messageDynamicFieldChange = (field: FieldTypeFormated) => (value: string | undefined) => {
    if (value && field.Type === DataType.Date) {
      value = formatIntizaDate(new Date(value));
    } else if (value && field.Type === DataType.List) {
      console.log({ field })
      value = field.ListItems.find(el => el.Value === value)?.AdditionalDefinitionItemID.toString() || '';
    }

    const newFieldData = [
      ...fieldData.filter(x => x.Id !== field.AdditionalDefinitionID),
      { ...field, Id: field.AdditionalDefinitionID, Value: value ?? "" }
    ];
    setFieldData([...newFieldData]);
  };

  const onFilesChange = (data: { id: string, name: string }[]) => setMessageData(x => ({ ...x, files: data.map(z => z.id) }))

  return (
    <>
      <div className="tabBody">
        {show && dataFieldsFormated.map((fieldFormated: FieldTypeFormated, i: number) => {
          const field = fieldData.find(el => el.Id === fieldFormated.Id)!;
          return <FieldEdit
            key={i}
            field={field}
            requiredManager={requiredManager}
            onChange={messageDynamicFieldChange(field)}
          />
        })}

        {show && <hr />}

        <div className={"d-flex input-column mb-3"}>
          <label className="form-label">{TranslationService.translate.YourMail}</label>
          <div><TextEditor onChange={mailChange} defaultValue={''} /></div>
          <ValidationMessage onChange={mailChange} defaultValue={messageData.email} />
        </div>

        <div className={"d-flex input-column mb-3"}>
          <label className="form-label">{TranslationService.translate.YourMessage}</label>
          <TextAreaBigEditor onChange={commentChange} rows={6} />
          <ValidationMessage onChange={commentChange} defaultValue={messageData.comment} />
        </div>

        <div className=" pt-2">
          <MultiFileUpload onFilesChange={onFilesChange} canEdit downloadFile={FilePortalService.download} token={tokenId} variant />
        </div>

      </div>
      <FloatingPanelFooter>
        <button className='btn btn-primary me-3' onClick={sendMessage}>
          {TranslationService.translate.Send}
          {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
        </button>
      </FloatingPanelFooter>
    </>
  )
}

type FieldEditType = {
  field: FieldTypeFormated,
  onChange: (value?: string) => void,
  requiredManager: RequiredManager
}

const FieldEdit = ({ field, onChange, requiredManager }: FieldEditType) => {
  let editor = undefined;
  const displayValue = field.Value;

  if (field.Mandatory) {
    onChange = requiredManager.makeRequired(onChange)
  }

  switch (field.Type) {
    case DataType.List:
      const drpItems = field.ListItems.map((x: any) => ({ value: x.Value, text: x.Value }));
      editor = <Dropdown optionLabel={TranslationService.translate.Select} onChange={onChange} items={drpItems} />;
      break;
    case DataType.Date:
      let date = undefined;
      let val = date ? ifOldFormat(date) : undefined;
      editor = <DatePickerEditor onChange={onChange} defaultValue={val} />;
      break;
    case DataType.Number:
    case DataType.Currency:
      editor = <TextEditorNumber onChange={onChange} defaultValue={displayValue} />;
      break;
    case DataType.Group:
      editor = <AutocompleteGroup onChange={x => onChange(x?.value)} clientId={'-1'} />;
      break;
    case DataType.Phone:
    case DataType.Text:
    case DataType.Link:
    default:
      editor = <div><TextEditor onChange={onChange} defaultValue={displayValue} /></div>;
      break;
  }

  return (
    <div className={"d-flex input-column mb-3"}>
      <label className="form-label">
        {field.Title}
      </label>
      {editor}
      <ValidationMessage onChange={onChange} defaultValue={field.Value} />
    </div>
  );
}
