import CompanyService from "../../../../services/CompanyService";
import { FilterCollection } from "../../../../services/FilterService";
import { PickByValue } from "../../../../utils/Utils";
import AdvancedFilters, { AdvancedFiltersContext } from "../../../shared/components/AdvancedFilters";
import Dropdown from "../../../shared/components/Dropdown";
import { CheckBoxEditor } from "../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../services/TranslationService";
import Alarm from "./Components/Alarm";
import DateFormat from "./Components/DateFormat";
import Dictionaries from "./Components/Dictionaries";
import EditItem from "./Components/EditItem";
import ExcelOptions from "./Components/ExcelOptions";
import PrecedingProcesses from "./Components/PrecedingProcesses";
import SeparatorWithFixedColumns from "./Components/SeparatorWithFixedColumns";
import UploadTemplate from "./Components/UploadTemplate";
import ImportTemplateEditBase, { TemplateField, ImportTemplateComponentProps } from "./ImportTemplateEditBase";
import { renderToStaticMarkup } from "react-dom/server";
import ImportTemplateService from "../../../../services/ImportTemplateService";
import AdvancedFilterService from "../../../../services/AdvancedFilterService";
import { useContext, useEffect } from "react";

const ImportTemplateEditInvoiceUpdate = ({ data, reload, importsList = undefined, entityId = undefined }: { data?: ImportTemplateListResponse.Item, reload: () => void, importsList?: ImportTemplateListResponse, entityId?: number }) => {
    const requiredIds = ["contact", "referenceNumber", "amount"];
    const advancedFiltersContext = useContext(AdvancedFiltersContext);
    useEffect(() => {
        advancedFiltersContext.setShow(true);
    }, [advancedFiltersContext]);

    const Header = () => {
        return (<div className="mb-3 mt-4">
            <h5 className="mb-4">{TranslationService.translate.ImportConfigurationOfNewInvoices}</h5>
            <details className="mt-3">
                <summary className="ps-1"><h6 style={{ display: "unset" }}>{TranslationService.translate.SeeTheRequirementsOfTheFileToImport}</h6></summary>
                {TranslationService.translate.RequirementsOfTheFileToImport.split("\n").map((x, i) => <div key={i} className="text-muted ps-3">{x}</div>)}
            </details>
        </div>);
    };

    return (
        <ImportTemplateEditBase  entityId={entityId} AdvancedOptions={AdvancedOptions} Header={Header} reload={reload} requiredFieldIds={requiredIds} data={data} />
    );
};


const AdvancedOptions = ({ data, updateModel, model, setModel, importsList, }: ImportTemplateComponentProps) => {
    const updateNumberModel = <T extends keyof PickByValue<ImportTemplateListResponse.Item, number | undefined>,>(key: T) => (value: number) => {
        setModel(x => ({ ...x, [key]: value }));
    };
    const applyFilters = (filters: string[] | undefined) => setModel(x => ({ ...x, IOFilter: AdvancedFilterService.filterStringsToQueryString(filters) }));
    const fields: TemplateField[] = ImportTemplateService.getTemplateFields(5);

    const fieldsKeyValueDup = fields.map(x => {
        const div = document.createElement("div");
        div.innerHTML = typeof x.label === "string" ? x.label : renderToStaticMarkup(x.label()).trim();
        return { value: x.key, text: div.innerText };
    });

    const fieldsKeyValue = [...new Set(fieldsKeyValueDup.map(x => x.value)).values()].map(x => fieldsKeyValueDup.find(y => y.value === x)!);

    return (<div>
        <small className="text-muted">{TranslationService.translate.IfYouLeaveFormatBlank}</small>
        <DateFormat defaultValue={data?.DateFormat} updateModel={updateModel("DateFormat")} label={TranslationService.translate.IssueDateFormat} />
        <DateFormat defaultValue={data?.DueDateFormat} updateModel={updateModel("DueDateFormat")} label={TranslationService.translate.DueDateFormat} />
        <CheckBoxEditor message={TranslationService.translate.UpdateExistingInvoiceDataWhenSynchronizing} onChange={updateModel("UpdateIOs")} defaultValue={data?.UpdateIOs} />
        {CompanyService.getIsAdmin() &&
            <div className="py-2 mb-3" style={{ paddingLeft: 5, marginLeft: -5, backgroundColor: "#f5eebe" }}>
                <CheckBoxEditor message={"Actualizar datos de facturas pagadas"} onChange={updateModel("IncludePaid")} defaultValue={data?.IncludePaid} />
                <CheckBoxEditor message={"Procesar facturas con monto 0 en el archivo"} onChange={updateModel("processZeroIOs")} defaultValue={data?.processZeroIOs} />
            </div>
        }
        <EditItem label={TranslationService.translate.WhenInvoiceRepeated} ><Dropdown onChange={updateNumberModel("OnRepeat")} defaultValue={data?.OnRepeat} items={[{ value: 0, text: TranslationService.translate.DiscardFile }, { value: 1, text: TranslationService.translate.DiscardRepeatedInvoices }, { value: 2, text: TranslationService.translate.SumRepeatedInvoicesAmount }, { value: 3, text: TranslationService.translate.TakeFirst }, { value: 4, text: TranslationService.translate.TakeLast },]} /></EditItem>
        <UploadTemplate formatsAllowed=".xlsx,.csv" model={model} updateModel={updateModel} />
        <SeparatorWithFixedColumns updateModel={updateModel} data={data} model={model} />
        <ExcelOptions data={data} updateModel={updateModel} />
        <hr className="my-5" />
        <Dictionaries data={data} model={model} setModel={setModel} />
        {CompanyService.getSetting("partialiosync") && <><hr className="my-5" />
            <h5 className="mb-3">{TranslationService.translate.FilterInvoices}</h5>
            <AdvancedFilters page={FilterCollection.Invoice} onFilterApply={applyFilters} defaultValue={data?.IOFilter} allowClosing={false} />
        </>}
        <hr className="my-5" />
        <EditItem label={TranslationService.translate.UpdateDataUsingTheField} ><Dropdown onChange={updateModel("CustomPersonIdentityID")} defaultValue={data?.CustomPersonIdentityID} items={fieldsKeyValue} /></EditItem>
        <hr className="my-5" />
        <PrecedingProcesses data={data} model={model} setModel={setModel} importsList={importsList} />
        <hr className="my-5" />
        <Alarm data={data} updateModel={updateModel} setModel={setModel} model={model} />
    </div>);
};

export default ImportTemplateEditInvoiceUpdate;