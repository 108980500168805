import { useEffect, useMemo, useState } from "react";
import { Field } from "../../action/Edit/components/FieldHelpers";
import Dropdown from "./Dropdown";
import { TranslationService, TranslationsKey } from "../../../services/TranslationService";
import ImportTemplateService from "../../../services/ImportTemplateService";
import { handleErrorWithToast, useDatasource } from "../../../utils/RequestUtils";
import Loading from "./Loading";
import ErrorMessage from "./Error";
import ImportStatus from "./ImportStatus";
import { ToastService } from "../bootstrap/Toast";
import { ImportService } from "../../../services/ImportService";
import { ImportPreviewResponse } from "../../../entities/import/ImportPreview";
import { parseIntOrDefault } from "../../../utils/ParseUtils";
import FileService from "../../../services/FileService";
import { ImportTemplateListResponse } from "../entities/ImportTemplateListResponse";
import { Switch } from "../../../utils/Utils";
import ProcessService from "../../../services/ProcessService";
import DictionaryService from "../../../services/DictionaryService";
import { DictionaryListResponse } from "../../../entities/dictionary/DictionaryListResponse";
import { Link, useHistory } from "react-router-dom";
import CompanyService from "../../../services/CompanyService";
import TooltipComponent from "../TooltipComponent";

const ImportTemplateManual = () => {
    type ImportTemplateTypeInfo = {
        titleKey: TranslationsKey;
        descriptionKey?: TranslationsKey;
        templates: []
    }
    enum Status {
        NoFile,
        Loading,
        NeedAproval,
        Imported,
        Error,
    }
    const { value: importTemplateList, isError, isLoading, reload } = useDatasource(ImportTemplateService.getList, []);
    const { location: { search }, push } = useHistory();
    const urlParams = new URLSearchParams(search);
    const entityId = parseIntOrDefault(urlParams.get("entityId"), 0);
    const templateTypeId = entityId;
    const [template, setTemplate] = useState<ImportTemplateListResponse.Item>();
    const [importStatus, setImportStatus] = useState(Status.NoFile);
    const [fileName, setFileName] = useState<false|string>(false);
    const [preview, setPreview] = useState<ImportPreviewResponse>();
    const [newImport, setImport] = useState<{ async: 0 | 1, importid: number, processid: number }>();
    const [dictionaryTemplates, setDictionaryTemplates] = useState<DictionaryListResponse.Dictionary[]>([]);

    const onFileUpload = async (fileList: FileList | null) => {
        const validExtensions = [".xls", ".xlsx", ".zip", ".csv"];
        if (!(fileList?.length) || template === undefined) { return; }
        const file = fileList[0];
        if (file.size > 24000000) {
            ToastService.showToast(TranslationService.translate.FileSizeError, undefined, "danger");
            return false;
        }
        if (validExtensions && !validExtensions.find(x => file.name.endsWith(x))) {
            ToastService.showToast(TranslationService.translate.WarningFileExtention, undefined, "danger");
            return false;
        }
        setFileName(file.name);
        setPreview(undefined);
        setImportStatus(Status.Loading);
        const fileUpload = await handleErrorWithToast(FileService.upload(file));
        const result = await handleErrorWithToast(ImportService.set(template.ImportTemplateID, fileUpload.id),
            () => setImportStatus(Status.Error));
        if (result.async === 1) {
            ToastService.showToast(TranslationService.translate.ProcessRunning, undefined, "success");
            setImportStatus(Status.Imported);
            return;
        }
        setImport(result);
        const preview = await handleErrorWithToast(ImportService.preview(result.importid),
            () => setImportStatus(Status.Error));
        setPreview(preview);
        setImportStatus(Status.NeedAproval);
    };

    useEffect(() => {
        if (templateTypeId === 11 && dictionaryTemplates.length === 0) {
            const fixDictionary = async () => {
                const dictionaries = await handleErrorWithToast(DictionaryService.getList());
                setDictionaryTemplates(dictionaries.list);
            };
            fixDictionary();
        }
    }, [dictionaryTemplates.length, templateTypeId]);

    const typeInfo = useMemo(() => {
        if (isLoading || isError) {
            return {} as ImportTemplateTypeInfo;
        }
        const titles: TranslationsKey[] = [
            "ImportListOfOutstandingInvoices",
            "UpdateCustomerData",
            "ImportPayments",
            "ImportComments",
            "ImportInvoiceFiles",
            "UpdateMultipleInvoices",
            "InvoiceAutoUpdates",
            "MassInvoiceDeletion",
            "ImportListOfNewInvoices",
            "CustomerAutoUpdates",
            "NotAssigned",
            "ImportDictionary"
        ];
        const titleKey = titles[templateTypeId] ?? "ImportListOfOutstandingInvoices";
        const templates = importTemplateList!.list.filter(x => x.entity === templateTypeId).map(x => ({ text: x.Name, value: x }));

        if (dictionaryTemplates.length) {
            templates.forEach(y => {
                y.text = dictionaryTemplates.find(x => x.ImportTemplateID === y.value.ImportTemplateID)?.Name ?? y.text;
            });
        }
        if (!templates.find(x => x.value === template)) {
            setTemplate(templates[0]?.value);
        }
        const descriptionKey = Switch(
            [templateTypeId === 4, "SelectAZipContainingFilesNamed"],
            [templateTypeId === 11, "WarningOnceRecievedTheFile"],
        );
        return {
            titleKey,
            descriptionKey,
            templates,
        };
    }, [importTemplateList, isError, isLoading, template, templateTypeId, dictionaryTemplates]);

    if (!CompanyService.canDo("importdata")) {
        ToastService.showToast("No tienes permiso para realizar esta acción", undefined, "danger");
        push("/");
        return <></>;
    }

    if (isLoading) {
        return <Loading />;
    }
    if (isError) {
        return <ErrorMessage onRefresh={reload} />;
    }
    if (typeInfo.templates.length === 0) {
        ImportTemplateService.showImportTemplateEditModal(undefined, importTemplateList, reload, entityId);
        return <></>;
    }
    const submit = async () => {
        setImportStatus(Status.Loading);
        const result = await ImportService.importData(newImport!.importid);
        if (result instanceof Error) {
            setImportStatus(Status.Error);
        } else {
            setImportStatus(Status.Imported);
        }
    };

    const back = () => {
        setImportStatus(Status.NoFile);
        setPreview(undefined);
    };

    const runAuto = async () => {
        if (!template) {
            return;
        }
        ProcessService.showResultToast(ImportService.runAuto(template.ImportTemplateID));
    };

    return (
        <div className="container-fluid padding">
            <div className="card">
                <h2 className="h2 my-3 mb-4">{TranslationService.translate[typeInfo.titleKey]}
                    {template && entityId === 4 && <Link to={`/${TranslationService.currentLanguage}/import/filelog`} className="btn btn-link ms-3 pb-2">{TranslationService.translate.ViewLog}</Link>}</h2>
                <Field title={typeInfo.templates.length <= 1 ? undefined : TranslationService.translate.ProcessTheFileUsingTheTemplate} colClass="col-xxl-5 col-7">
                    <div className="row align-items-center gap-x-3">
                        {typeInfo.templates.length > 1 && templateTypeId !== 7 && <Dropdown onChange={x => setTemplate(x)} items={typeInfo.templates} selectClasses="col" defaultValue={template} />}
                        {template && [6, 9].includes(entityId) ? <>
                            <div className="col-auto">
                                <button className="btn btn-primary" onClick={runAuto}>{TranslationService.translate.RunNow}</button>
                            </div>
                        </> :
                            <div className="col-auto">
                                <input type="file" onChange={e => { onFileUpload(e.target.files); e.target.value = ""; }}
                                    className="d-none" id="file-input-new-template" />
                                <label htmlFor="file-input-new-template" className="btn btn-primary mb-0">
                                    <TooltipComponent title={fileName}>
                                        {importStatus === Status.NoFile ? TranslationService.translate.ImportFile : TranslationService.translate.Selected}
                                    </TooltipComponent>
                                </label>
                            </div>
                        }
                        <div className="col-auto">
                            <button className="btn btn-link"
                                onClick={() => ImportTemplateService.showImportTemplateEditModal(template, importTemplateList, reload)}>
                                {TranslationService.translate.ChangeSettings}
                            </button>
                        </div>
                    </div>
                </Field>
                {typeInfo.descriptionKey && <p>{TranslationService.getTranslation(typeInfo.descriptionKey.toString())}</p>}
                {importStatus === Status.Loading && <div className="loading my-4">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <i className="fa-solid fa-spinner fa-spin fs-4"></i>
                    </div>
                    <p>{TranslationService.translate.ProcessingData}...</p>
                </div>}
                {importStatus === Status.NeedAproval && preview && <>
                    <hr />
                    <ImportStatus preview={preview} submit={submit} back={back} template={template!} />
                </>}
                {importStatus === Status.Imported && <div className="alert alert-success" role="alert">
                    <i className="fa-regular fa-circle-check me-2"></i>Importación finalizada correctamente
                </div>}
                {importStatus === Status.Error && <div className="alert alert-danger" role="alert">
                    <i className="fa-regular fa-triangle-exclamation me-2"></i>Ocurrió un error con la importación
                </div>}
            </div>
        </div>
    );

};

export default ImportTemplateManual;