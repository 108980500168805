import { AveragePaymentResponse } from "../entities/reports/ReportReceivablesSummary/AveragePaymentResponse";
import { DebtAgingResponse } from "../entities/reports/ReportReceivablesSummary/DebtAgingResponse";
import { MonthlyDataRow } from "../entities/reports/ReportReceivablesSummary/MonthlyDataResponse";
import { ReportReceivablesResponse } from "../entities/reports/ReportReceivablesSummary/ReceivablesResponse";
import { formatCurrency, formatInteger, formatIntizaDate } from "../utils/FormatUtils";
import CompanyService from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

export class ReportReceivablesSummaryService {
    public static getReceivables(filter: string): Promise<Error | ReportReceivablesResponse> {
        return RequestService.post("/client/sellstocollect", { filter, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getAveragePayment(filter: string, startDate: Date): Promise<Error | AveragePaymentResponse> {
        return RequestService.post("/client/averagepaymentterm", { startDate: formatIntizaDate(startDate), filter, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getDebtMonthly(filter: string, startDate: Date): Promise<Error | DebtAgingResponse> {
        return RequestService.post("/client/agingmonthly", { startDate: formatIntizaDate(startDate), filter, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getDuePercentageMonthly(filter: string, startDate: Date): Promise<Error | DebtAgingResponse> {
        return RequestService.post("/client/duepercentagemonthly", { startDate: formatIntizaDate(startDate), filter, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getDueDaysMonthly(filter: string, startDate: Date): Promise<Error | DebtAgingResponse> {
        return RequestService.post("/client/duedaysmonthly", { startDate: formatIntizaDate(startDate), filter, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getMonthlyData(filter: string): Promise<Error | { data: MonthlyDataRow[] }> {
        return RequestService.post("/client/monthlydata", { filter, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static exportMonthlyData(filter: string) {
        ExportService.requestExport("/client/exportmonthlydata", { filter, additionaldefinitions: CompanyService.getAdditionalDefinitions() }, 1);
    }
    public static chartLineOptions(categories: string[], data: number[], monthCount: number, colors?: string[], tooltipFormat?: Highcharts.TooltipFormatterCallbackFunction, yLabelFormatter?: Highcharts.AxisLabelsFormatterCallbackFunction): Highcharts.Options {
        if (typeof (colors) == "undefined" || colors == null)
            colors = ["#1647b5", "#ec0423"];

        tooltipFormat = tooltipFormat ?? function () { return this.y + " " + TranslationService.translate.Days; };
        const options: Highcharts.Options = {
            colors: colors,
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadPDF"]
                    }
                }
            },
            legend: {
                enabled: false,
                floating: true,
                borderWidth: 0,
                itemMarginTop: 0,
                itemMarginBottom: 0
            },
            credits: { enabled: false },
            title: {
                text: ""
            },
            xAxis: {
                categories: categories,
                labels: {
                    style: {
                        fontSize: "9px"
                    }
                },
                min: 12 - monthCount,
                max: 11,
            },
            yAxis: {
                title: { text: "" },
                gridLineColor: "#e3e3e3",
                gridLineWidth: 0,
                min: 0,
                labels: {
                    formatter: yLabelFormatter ?? function (ctx) {
                        return formatInteger(parseFloat(this.value.toString())) + " " + TranslationService.translate.Days;
                    }
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: "#808080"
                }]
            },
            series: [{ type: "line", data: data }],
            tooltip: { formatter: tooltipFormat },
            chart: {
                height: 200,
            }
        };
        return options;
    }
    public static chartPieOptions(due: number, noDue: number) {
        const newData = [
            [TranslationService.translate.NoDues, noDue],
            [TranslationService.translate.Overdue, due],
        ];
        const options: Highcharts.Options = {
            colors: ["#1647b5", "#EC0423"],
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadPDF"]
                    }
                }
            },
            plotOptions: {
                pie: {
                    dataLabels: { enabled: false },
                    showInLegend: true,
                },
                series: {
                    shadow: false
                }
            },
            loading: {
                hideDuration: 500,
                showDuration: 500
            },
            legend: {
                enabled: true,
                borderWidth: 0,
                itemMarginTop: 0,
                itemMarginBottom: 0,
            },
            credits: { enabled: false },
            chart: {
                height: 200,
            },
            title: {
                text: ""
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                title: { text: "" },
                gridLineColor: "#e3e3e3",
                gridLineWidth: 1,
                labels: {
                    formatter: function (this: { value: number | string }) {
                        return formatCurrency(parseFloat(this.value.toString())) ?? "";
                    }
                }
            },
            tooltip: {
                formatter: function (this: Highcharts.TooltipFormatterContextObject, tooltip: Highcharts.Tooltip) {
                    return "<b>" + this.key + "</b><br/>" + (formatCurrency(this.y ?? 0) ?? "") + "<br/>" + Math.round(this.percentage) + "%";
                }
            },
            series: [{
                type: "pie",
                name: "",
                data: newData,
                // width: 100
            }],
        };
        return options;
    }
}