import { ImportExportGet } from "../entities/import/ImportExportGet";
import ImportTemplateEditActivity from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditActivity";
import { TemplateField, additionalDefsToFieldData, createFieldData } from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditBase";
import ImportTemplateEditClient from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditClient";
import ImportTemplateEditClientAutomatic from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditClientAutomatic";
import ImportTemplateEditCurrencies from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditCurrencies";
import ImportTemplateEditDictionary from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditDictionary";
import ImportTemplateEditFile from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditFile";
import ImportTemplateEditInvoice from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoice";
import ImportTemplateEditInvoiceAutomatic from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoiceAutomatic";
import ImportTemplateEditInvoiceDelete from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoiceDelete";
import ImportTemplateEditInvoiceNew from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoiceNew";
import ImportTemplateEditInvoiceUpdate from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditInvoiceUpdate";
import ImportTemplateEditTransaction from "../pages/company/Configuration/ImportTemplate/ImportTemplateEditTransaction";
import FloatingPanelService from "../pages/shared/FloatingPanel";
import { ImportTemplateListResponse } from "../pages/shared/entities/ImportTemplateListResponse";
import { addIf, Switch } from "../utils/Utils";
import CompanyService, { Entities } from "./CompanyService";
import InvoiceService from "./InvoiceService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

export enum ImportTemplateId {
    IO = 0,
    Person = 1,
    Transaction = 2,
    Activity = 3,
    Files = 4,
    IOUpdate = 5,
    IOAutomaticUpdate = 6,
    IODelete = 7,
    NewIO = 8,
    PersonAutomaticUpdate = 9,
    Currencies = 10,
    Dictionary = 11
}
class ImportTemplateService {
    public static getList(): Promise<ImportTemplateListResponse | Error> {
        return RequestService.post("/importtemplate/list", {});
    }

    public static set(data: ImportTemplateListResponse.Item): Promise<ImportTemplateListResponse | Error> {
        return RequestService.post("/importtemplate/set", { item: data, templateFileID: data.templateFileID?.replace("temp-", "") });
    }

    public static runIOUpdate(): Promise<ImportTemplateListResponse | Error> {
        return RequestService.post("/importtemplate/RunIOUpdate", {});
    }

    public static runPersonUpdate(): Promise<ImportTemplateListResponse | Error> {
        return RequestService.post("/importtemplate/RunPersonUpdate", {});
    }

    public static showImportTemplateEditModal(template?: ImportTemplateListResponse.Item, importsList: ImportTemplateListResponse | undefined = undefined, onSubmit: () => void = () => undefined, templateEntityId: undefined | number = undefined) {
        const Editors = [
            ImportTemplateEditInvoice,
            ImportTemplateEditClient,
            ImportTemplateEditTransaction,
            ImportTemplateEditActivity,
            ImportTemplateEditFile,
            ImportTemplateEditInvoiceUpdate,
            ImportTemplateEditInvoiceAutomatic,
            ImportTemplateEditInvoiceDelete,
            ImportTemplateEditInvoiceNew,
            ImportTemplateEditClientAutomatic,
            ImportTemplateEditCurrencies,
            ImportTemplateEditDictionary
        ];
        const Edit = Editors[template?.entity ?? templateEntityId ?? -1];
        if (Edit === undefined) {
            alert("not implemented esese");
            return;
        }
        FloatingPanelService.showPanel({
            children: <Edit data={template} reload={onSubmit} importsList={importsList} entityId={template?.Entity ?? templateEntityId} />,
            title: TranslationService.translate.EditImportTemplate,
            width: 800,
            height: 600,
            position: "center",
        });
    }

    public static getExportTemplate(importtemplateid: number): Promise<ImportExportGet | Error> {
        return RequestService.post("/exporttemplate/get", { importtemplateid });
    }

    public static setExportTemplate(importtemplateid: number, list: {
        Field?: number | string,
        Position: number,
        Entity: number,
    }[]): Promise<unknown | Error> {
        return RequestService.post("/exporttemplate/set", { importtemplateid, list });
    }

    public static getTemplateFields(entityId: number | undefined): TemplateField[] {
        if (entityId === undefined || entityId === 4) {
            return [];
        }
        const isPersonEntity = [ImportTemplateId.Person, ImportTemplateId.PersonAutomaticUpdate].includes(entityId);
        const isInvoiceEntity = [ImportTemplateId.IO, ImportTemplateId.IOAutomaticUpdate, ImportTemplateId.IODelete, ImportTemplateId.IOUpdate, ImportTemplateId.NewIO].includes(entityId);

        const additionals = Switch(
            [isPersonEntity, CompanyService.getAdditionalDefinitionsFiltered(Entities.Client)],
            [isInvoiceEntity, CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice)],
            [ImportTemplateId.Transaction === entityId, CompanyService.getAdditionalDefinitionsFiltered(Entities.Payment)],
            [ImportTemplateId.Activity === entityId, CompanyService.getAdditionalDefinitionsFiltered(Entities.Activity)],
        ) ?? [];

        const fields = Switch(
            [isPersonEntity, personFields],
            [isInvoiceEntity, () => invoiceFields(entityId)],
            [ImportTemplateId.Transaction === entityId, paymentFields],
            [ImportTemplateId.Activity === entityId, activityFields],
            [ImportTemplateId.Dictionary === entityId, dictionaryFields],
        ) ?? [];
        return [
            ...fields,
            ...additionalDefsToFieldData(additionals),
        ];
    }
}

function invoiceFields(entityId: number) {
    const allowCustomField = CompanyService.getSetting("allowcustomfieldimport") && entityId === ImportTemplateId.IOUpdate;
    return [
        createFieldData("contact", CompanyService.getPersonKey() === -21 ? TranslationService.translate.Client : CompanyService.getAdditionalDefinitions().find(x => x.AdditionalDefinitionID === CompanyService.getPersonKey())?.Name ?? TranslationService.translate.Client, (entityId !== ImportTemplateId.IOUpdate || CompanyService.getSetting("allowioupdatebyreferencenumber")) && !allowCustomField),
        ...addIf(CompanyService.getGroupName()?.length > 0, createFieldData("group", CompanyService.getGroupName())),
        createFieldData("createdDate", TranslationService.translate.EmmitedDate, entityId === ImportTemplateId.IO || entityId === ImportTemplateId.NewIO),
        createFieldData("referenceNumber", InvoiceService.getReferenceNumberName(), !allowCustomField),
        ...addIf(CompanyService.getSetting("hascurrency"), createFieldData("currency", TranslationService.translate.Currency)),
        createFieldData("amount", TranslationService.translate.AmountButPendingSometimes),
        createFieldData("duedate", TranslationService.translate.DueDate),
        ...addIf(CompanyService.getSetting("showiostatus"), createFieldData("iostatus", TranslationService.translate.IOStatus)),
        createFieldData("description", TranslationService.translate.Observations),
    ];
}

function personFields() {
    return [
        createFieldData("name", TranslationService.translate.Name, CompanyService.getPersonKey() === -21 && !CompanyService.getSetting("allowcustomfieldimport")),
        createFieldData("contact", TranslationService.translate.Contact),
        createFieldData("email", TranslationService.translate.Email),
        createFieldData("phone", TranslationService.translate.Phone),
        createFieldData("address", TranslationService.translate.Address),
        createFieldData("comments", TranslationService.translate.Notes),
        createFieldData("creditlimit", TranslationService.translate.CreditLimit),
    ];
}

function paymentFields() {
    return [
        createFieldData("client", CompanyService.getPersonKey() === -21 ? TranslationService.translate.Client : CompanyService.getAdditionalDefinitions().find(x => x.AdditionalDefinitionID === CompanyService.getPersonKey())?.Name ?? TranslationService.translate.Client, CompanyService.getSetting("allowioupdatebyreferencenumber")),
        createFieldData("referenceNumber", InvoiceService.getReferenceNumberName(), CompanyService.getSetting("allowcustomfieldimport")),
        createFieldData("date", TranslationService.translate.Date, true),
        createFieldData("amount", TranslationService.translate.Amount, true),
        createFieldData("comments", TranslationService.translate.Observations),
        ...addIf(CompanyService.getTransactionTypes()?.length > 0, createFieldData("transactiontype", TranslationService.translate.Type))
    ];
}

function activityFields() {
    return [
        createFieldData("date", TranslationService.translate.Date),
        createFieldData("user", TranslationService.translate.User),
        createFieldData("client", TranslationService.translate.Client, true),
        ...addIf(CompanyService.getGroupName()?.length > 0, createFieldData("group", CompanyService.getGroupName())),
        createFieldData("content", TranslationService.translate.Content, true),
        createFieldData("tags", TranslationService.translate.Tags),
        createFieldData("activitytype", TranslationService.translate.ActivityType),
    ];
}

function dictionaryFields() {
    return [
        createFieldData("term", TranslationService.translate.Term, true),
        createFieldData("value", TranslationService.translate.NewValue, true),
    ];
}

export default ImportTemplateService;