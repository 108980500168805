import CompanyService from "../services/CompanyService";
import { MapIf } from "./Utils";

export function parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString().split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export function parseIntOrDefault(e: string | undefined | null, d?: number): number {
    if (e === undefined || e === null) {
        return d ?? 0;
    }
    const p = parseInt(e);
    return Number.isInteger(p) ? p : d ?? 0;
}

export function parseDate(value: string | undefined): Date | undefined {
    if (value === undefined || value === null) {
        return undefined;
    }
    let date = new Date();
    if (value.length === 8 || /* yyyyMMdd */
        value.length === 10 || /* yyyy/MM/dd or yyyy-MM-dd */
        value.length === 14 /* yyyyMMdd hh:mm */) {
        if (value.length === 10) {
            value = value.replaceAll("/", "").replaceAll("-", "");
        }
        date.setFullYear(
            parseInt(value.substring(0, 4)),
            parseInt(value.substring(4, 6)) - 1,
            parseInt(value.substring(6, 8)),
        );
        if (value.length === 14) {
            date.setHours(
                parseInt(value.substring(9, 11)),
                parseInt(value.substring(12, 14)),
            );
        }
        if (!Number.isNaN(date.getTime())) {
            return date;
        }
    }
    date = new Date(value);
    if (!Number.isNaN(date.getTime())) {
        return date;
    }
    return undefined;
}

function addHours(date: Date, hours: number) {
    return new Date(date.getTime() + hours * 3_600_000 /* hs to ms */);
}

export function restTimezone(date: Date) {
    return addHours(date, -CompanyService.getTimezone() / 60);
}

export function addTimezone(date: Date) {
    return addHours(date, CompanyService.getTimezone() / 60);
}

export function MapIfHasHours(date: Date, map: (date: Date) => Date) {
    return MapIf(date, (date.getHours() + date.getMinutes() + date.getMilliseconds()) > 0, map);
}

export function validateMail(mail: string | undefined) {
    if (!mail) {
        return false;
    }
    return mail.includes("@") && mail.includes(".");
}

export function validateMailOrEmpty(mail: string | undefined) {
    if (!mail) {
        return true;
    }
    return mail.includes("@") && mail.includes(".");
}