export const InvoiceIconActive = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1428 13.286V7.28596L9.85706 3.00024H5.57134C5.11668 3.00024 4.68065 3.18086 4.35916 3.50235C4.03767 3.82384 3.85706 4.25987 3.85706 4.71453V13.286C3.85706 13.7406 4.03767 14.1767 4.35916 14.4981C4.68065 14.8196 5.11668 15.0002 5.57134 15.0002H12.4285C12.8831 15.0002 13.3192 14.8196 13.6407 14.4981C13.9622 14.1767 14.1428 13.7406 14.1428 13.286V13.286Z" fill="#0076B6" stroke-width="1.38107" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.85693 3.00037V5.57179C9.85693 6.02645 10.0375 6.46249 10.359 6.78398C10.6805 7.10547 11.1166 7.28608 11.5712 7.28608H14.1426" fill="#0076B6" stroke-width="1.38107" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const InvoiceIconInactive = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1428 13.286V7.28596L9.85706 3.00024H5.57134C5.11668 3.00024 4.68065 3.18086 4.35916 3.50235C4.03767 3.82384 3.85706 4.25987 3.85706 4.71453V13.286C3.85706 13.7406 4.03767 14.1767 4.35916 14.4981C4.68065 14.8196 5.11668 15.0002 5.57134 15.0002H12.4285C12.8831 15.0002 13.3192 14.8196 13.6407 14.4981C13.9622 14.1767 14.1428 13.7406 14.1428 13.286V13.286Z" fill="#595959" stroke-width="1.38107" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.85693 3.00037V5.57179C9.85693 6.02645 10.0375 6.46249 10.359 6.78398C10.6805 7.10547 11.1166 7.28608 11.5712 7.28608H14.1426" fill="#595959" stroke-width="1.38107" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
